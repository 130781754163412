/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
   *  Owl Carousel - Animate Plugin
   */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/*
   * 	Owl Carousel - Auto Height Plugin
   */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
   * 	Owl Carousel - Lazy Load Plugin
   */
.owl-carousel .owl-item {
  /**
              This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
              calculation of the height of the owl-item that breaks page layouts
           */
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""],
.owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
   * 	Owl Carousel - Video Plugin
   */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

// Custom Owl Carousel Settings
.owl-carousel {
  .owl-nav {
    display: none;

    .owl-prev {
      position: absolute;
      left: 0;
      width: rem(40px);
      height: rem(40px);
      padding: rem(5px);
      background-color: $white-bg !important;
      @include box-shadow(rem(2px) rem(2px) rem(5px) rgba(0, 0, 0, 0.25));
      top: calc(50% - 23px);
      @include border-radius(4px);

      &.disabled {
        display: none;
      }
    }

    .owl-next {
      position: absolute;
      left: calc(100% - 40px);
      width: rem(40px);
      height: rem(40px);
      padding: rem(5px);
      background-color: $white-bg !important;
      @include box-shadow(rem(-2px) rem(2px) rem(5px) rgba(0, 0, 0, 0.25));
      top: calc(50% - 23px);
      @include border-radius(4px);

      &.disabled {
        display: none;
      }
    }
  }

  @media screen and (min-width:1200px) {
    &:hover {
      .owl-nav {
        display: block;

        .owl-prev {
          .iconLeftChevron {
            background-position: rem(-58px) rem(-206px);
          }
        }

        .owl-next {
          .iconRightChevron {
            background-position: rem(-58px) rem(-206px);
          }
        }
      }
    }
  }
}

.timelineItems {
  @media screen and (max-width: 991px) {
    width: 100%;
    .owl-stage {
      padding-left: 15px !important;
    }
  }
  .owl-stage-outer {
    overflow-y: hidden;
  }
  .owl-nav {

    .owl-prev,
    .owl-next {
      position: relative;
      top: rem(-300px);
    }
  }
}


.rowCarousel {
  .owl-stage{
    padding-left: 0 !important;
  }
  @media screen and (max-width: 991px) {
    padding-right: 0;
    margin: 0;
    &.videosRow{
      width: calc(100% + 30px);
      margin-left: rem(-15px);
    }
    .owl-stage {
      padding-left: 15px !important;
    }
    .item {
      padding: 0;
    }
  }
}

// Story Carousel
.storyCarousel {
  .owl-item {
    -webkit-filter: opacity(50%);
    filter: opacity(50%);

    &.active {
      -webkit-filter: opacity(100%);
      filter: opacity(100%);
    }
  }

  .owl-dots {
    text-align: center;
    padding-top: rem(60px);

    @media screen and (max-width: 1600px) {
      padding-top: rem(30px);
    }

    @media screen and (max-width:1440px) {
      padding-top: rem(10px);
    }

    @media screen and (max-width:767px) {
      padding-top: rem(20px);
    }

    .owl-dot {
      margin-right: rem(10px);

      &.active {
        span {
          background: $brandBlue;
        }
      }

      span {
        width: rem(60px);
        height: rem(4px);
        background: $darkerWhite;
        display: inline-block;
        @include border-radius(rem(8px));

        @media screen and (max-width: 1199px) {
          width: rem(50px);
        }

        @media screen and (max-width:991px) {
          width: rem(40px);
        }

        @media screen and (max-width:767px) {
          width: rem(20px);
          height: rem(3px);
        }
      }
    }
  }
}

// Webinar Carousel
.webinarCarousel,
.quizCarousel,
.discussionCarousel {
  .clickableHeader{
    height: 48px;
    overflow: hidden;
    display: block;
  }
  @media screen and (max-width: 574px) {
    width: calc(100% + 30px);
    margin-left: rem(-15px);

    .owl-stage-outer {
      padding-left: rem(15px);
    }
  }

  .owl-stage {
    padding-left: 0 !important;
  }

  .owl-item img {
    &.webinarProfileImage {
      width: rem(97px);
      height: rem(97px);

      @media screen and (max-width: 574px) {
        width: rem(45px);
        height: rem(45px);
      }
    }
  }
}

// Story Carousel
.storyCarouselHeader {
  width: rem(520px);
  @media screen and (max-width: 991px) {
    .primaryElement {
      margin-bottom: rem(10px);
      font-size: rem(18px);
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.owl-carousel .owl-stage {
  display: flex;
}

.owl-carousel .owl-item {
  width: auto;
  height: 100%;

  .item {
    height: 100%;
    position: relative;

    .card {
      width: 100%;
      position: relative;
    }
  }
}