// Modal Styling
.modal {
    &.fullScreenModal {
        background: rgba($white-bg, 0.92);
    }
}

.close{
    opacity: 1;
}

.modal-dialog {

    // Full Screen Modal  
    &.fullScreenDialog {
        max-width: 100%;
        margin: 0;

        .modal-content {
            height: 100vh;
            background: transparent;
        }
    }
}





// Modal Video Story
.modalVideoStory{
    padding: rem(180px) 0;
    @media screen and (max-width: 1440px) {
        padding: rem(120px) 0;
    }
    @media screen and (max-width: 991px) {
        padding: rem(80px) 0;
    }
    @media screen and (max-width: 991px) {
        padding: rem(30px) 0;
    }
}

.popupVideoBlock{
    .blackHeader .blackHeaderText {
        background: #f0f0f1;
    }
}
.videoPopModal{
    @include transition(all 500ms cubic-bezier(0.390, 0.575, 0.565, 1.000));
}

.modalRightFlow{
    .modal-dialog{
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        .modal-content{
            height: 100%;
            border-radius:rem(8px) 0 0 rem(8px);
            .sortedMockTestLists{
                max-height: calc(100vh - 150px);
                overflow: auto;
            }
        }
    }
}