.contentBlockFixatBottom{
    @include transition(all cubic-bezier(1,-0.1,0.8,1) 0.5s);
    @media screen and (max-width:991px) {
        overflow: visible;
    }
    &.fixed_Bot{
        position: fixed;
        bottom: 0;
        @media screen and (max-width:991px) {
            overflow: visible;
            width: 100% !important;
            position: relative;
        }
    }
}
.currentAffairsTimeline{
    position: relative;
    @include transition(all linear 0.25s);
    overflow: hidden;
    width: 100%;
    @media screen and (max-width:991px) {
        overflow: visible;
        left: rem(-15px);
        width:calc(100% + 30px);
        &.dailyEventTimeline{
            left: 0;
            width:100%;    
            .timeline {
                max-width: 100%;
            }
        }
    }
}

.currentAffairsTimeline:before{
    content: "";
    width: rem(1px);
    height: 100%;
    background: $light-grey-bg;
    position: absolute;
    top: 0;
    left: rem(9px);
    @media screen and (max-width:991px) {
        display: none;
    }
}
.timeline{
    position: relative;
    margin-bottom: rem(20px);
    @media screen and (max-width:991px) {
        width: 100%;
        max-width: rem(328px);
    }
    @media screen and (max-width:767px) {
        max-width: 100%;
    }
    &.bottomTimeline{
        margin-bottom: 0;
    }
}
.timeline:before,
 .timeline:after{
    content: "";
    display: block;
    width: 100%;
    clear: both;
}

.timelineContent{
    padding-left: rem(65px);
    transition: all 0.3s ease 0s;
    @media screen and (max-width:991px) {
        padding-left: 0;
        margin-top: rem(42px);
    }
}

.timeLineDateWithToggleDetails{
    position: absolute;
    width: rem(40px);
    top: 0;
    left: 0;
    @media screen and (max-width:991px) {
        top: rem(-40px);
    }
}

.mainCurrentAffairs{
    .timeline{
        @media screen and (max-width:991px) {
            max-width: 100%;
            .timeLineDateWithToggleDetails{
                left: rem(10px);
            }
        }
    }   
}

// Timeline Copy Options
.toggleTimelineDetails{
    width: 12px;
    color: $black-text;
    display: block;
    @media screen and (min-width:1200px) {
        &:hover{
            .iconBars{
                background-position:rem(-10px) rem(-111px);
            }
            .iconSwitchList{
                background-position:rem(-25px) rem(-111px);
            }
        }
    }
}

.timelineDate{
    width: rem(48px);
    background: $white-bg;
    overflow: hidden;
    @include font(rem(12px), 400, null, 1.2, $lighter-black-text);
    padding: rem(5px) rem(10px);
    z-index: 3;
    @include box-shadow(0 2px 3px rgba($black, 0.3));
    margin: 0 rem(3px);
    span{
        @include font-size(14px);
        font-weight: 700;
        display: block;        
    }
    @media screen and (max-width:991px) {
        width: rem(64px);
        span{
            display: inline-block;
        }
    }
}

.currentAffairsHead{
    padding-left: rem(65px);
    @media screen and (max-width:991px) {
        padding-left:0;  
    }
}

// Current Affiars List
.currentAffairsList{
    display: none;
    height: rem(440px);
}
.articlesList{    
    position: relative;
    ul{       
        margin: 0;
        @include transition(all ease-out 0.5s);
        li{
            border-bottom: rem(1px) solid $light-grey-border;
            padding:rem(15px) 0 rem(10px);
            &:first-child{
                border-top: rem(1px) solid $light-grey-border  
            }
        }
    }
    .articlesNav{
        position: absolute;
        z-index: 3;
        width:100%;
        height: rem(30px);
        text-align: center;
        background: $white-bg;
        &.articlesNextNav{
            bottom: 0;
        }
    }
    &.relatedTopics{
        ul{
            li{
                &:first-child{
                    border-top:none;
                }
                &:last-child{
                    border-bottom: none;
                }
            }
        }
    }
}

.articlesScrollable{
    padding: rem(30px) 0;
    height: rem(410px);
    overflow: hidden;
    position: relative;
}


.yearTimeline{
    position: relative;
    .yearText{
        font-size: rem(18px);
        color: $brandBlue;
        font-weight: 800;
        padding: 0 rem(5px) rem(5px);
        @media screen and (max-width:991px) {
            padding-left:0;
        }
    }
    .timeline{
        .timelineContent{
            padding-left: rem(78px);
            @media screen and (max-width:991px) {
                padding-left: 0;
                margin-left: 0;
                padding-bottom: rem(20px);
            }
        }
    }
    .currentAffairsTimeline:before {
     left: rem(26px);
    }
}