// Default Directional Property Settings
@mixin _directional-property(
  $property,
  $suffix,
  $values
) {
  $top:    $property + "-top"    + if($suffix, "-#{$suffix}", "");
  $bottom: $property + "-bottom" + if($suffix, "-#{$suffix}", "");
  $left:   $property + "-left"   + if($suffix, "-#{$suffix}", "");
  $right:  $property + "-right"  + if($suffix, "-#{$suffix}", "");
  $all:    $property +             if($suffix, "-#{$suffix}", "");

  $values: _compact-shorthand($values);

  @if _contains-falsy($values) {
    @if nth($values, 1) { #{$top}: nth($values, 1); }

    @if length($values) == 1 {
      @if nth($values, 1) { #{$right}: nth($values, 1); }
    } @else {
      @if nth($values, 2) { #{$right}: nth($values, 2); }
    }

    @if length($values) == 2 {
      @if nth($values, 1) { #{$bottom}: nth($values, 1); }
      @if nth($values, 2) { #{$left}: nth($values, 2); }
    } @else if length($values) == 3 {
      @if nth($values, 3) { #{$bottom}: nth($values, 3); }
      @if nth($values, 2) { #{$left}: nth($values, 2); }
    } @else if length($values) == 4 {
      @if nth($values, 3) { #{$bottom}: nth($values, 3); }
      @if nth($values, 4) { #{$left}: nth($values, 4); }
    }
  } @else {
    #{$all}: $values;
  }
}


// Box shadow
@mixin  box-shadow($shadowParam){
    -webkit-box-shadow: $shadowParam;
    -moz-box-shadow: $shadowParam;
    -o-box-shadow: $shadowParam;
    box-shadow: $shadowParam;
}

// Pixels TO REM
$browser-context: 16px;

@function rem($pixels, $context: $browser-context) {
  @return ($pixels/$context)+rem;
}

// Font Size
@mixin font-size($sizeValue) {
    font-size: rem($sizeValue);
  }

// Fonts Mixins
@mixin font($size: false, $weight: false, $style: false, $lh: false, $colour: false) {
    @if $size {
      font-size: $size;
    }
    @if $weight {
      font-weight: $weight;
    }
    @if $style {
      font-family: $style;
    }
    @if $lh {
      line-height: $lh;
    }
    @if $colour {
      color: $colour;
    }
  }


// Background Image Pattern
$image-path        : '../img' !default;
$fallback-extension: 'png' !default;
$retina-suffix     : '@2x' !default;
@mixin background-image($name, $size: 100% auto, $repeat: no-repeat) {
  @if $size {
    background-size: $size;
  }
  @if $repeat {
    background-repeat: $repeat;
  }
  
  background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});
  background-image: url(#{$image-path}/#{$name}.svg);
  
  @include hidpi(1.5) {
    background-image: url(#{$image-path}/#{$name}#{$retina-suffix}.#{$fallback-extension});
    background-image: url(#{$image-path}/#{$name}.svg);
  }
}

// Overflow Ellipsis
// .element {
//     @include ellipsis;
//   }

// CSS Output
// .element {
//     display: inline-block;
//     max-width: 100%;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     word-wrap: normal;
//   }

@mixin ellipsis(
  $width: 100%,
  $display: inline-block
) {
  display: $display;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

// Properties Prefixer
///  .element {
///     @include prefixer(appearance, none, ("webkit", "moz"));
///   }
///
///   // CSS Output
///   .element {
///     -webkit-appearance: none;
///     -moz-appearance: none;
///     appearance: none;
///   }
///
/// @author Hugo Giraudel

@mixin prefixer(
  $property,
  $value,
  $prefixes: ()
) {
  @each $prefix in $prefixes {
    #{"-" + $prefix + "-" + $property}: $value;
  }
  #{$property}: $value;
}

// Hide and Unhide Visual Components
// .element {
//     @include hide-visually;
  
//     &:active,
//     &:focus {
//       @include hide-visually("unhide");
//     }
//   }

// CSS Output
// .element {
//     border: 0;
//     clip: rect(1px, 1px, 1px, 1px);
//     clip-path: inset(100%);
//     height: 1px;
//     overflow: hidden;
//     padding: 0;
//     position: absolute;
//     width: 1px;
//   }
  
//   .hide-visually:active,
//   .hide-visually:focus {
//     clip: auto;
//     clip-path: none;
//     height: auto;
//     overflow: visible;
//     position: static;
//     width: auto;
//   }
@mixin hide-visually($toggle: "hide") {
    @if not index("hide" "unhide", $toggle) {
      @error "`#{$toggle}` is not a valid value for the `$toggle` argument in " +
             "the `hide-visually` mixin. Must be either `hide` or `unhide`.";
    } @else if $toggle == "hide" {
      border: 0;
      clip: rect(1px, 1px, 1px, 1px);
      clip-path: inset(100%);
      height: 1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    } @else if $toggle == "unhide" {
      clip: auto;
      clip-path: none;
      height: auto;
      overflow: visible;
      position: static;
      white-space: inherit;
      width: auto;
    }
  }


// Hide Text
//   .element {
//     @include hide-text;
//   }
  
  // CSS Output
//   .element {
//     overflow: hidden;
//     text-indent: 101%;
//     white-space: nowrap;
//   }
  @mixin hide-text {
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
  }




///  Positioning
///   .element {
///     @include position(relative, 0 null null 10em);
///   }
///
///   // CSS Output
///   .element {
///     left: 10em;
///     position: relative;
///     top: 0;
///   }
///
/// 
///   .element {
///     @include position(absolute, 0);
///   }
///
///   // CSS Output
///   .element {
///     position: absolute;
///     top: 0;
///     right: 0;
///     bottom: 0;
///     left: 0;
///   }
@mixin position(
    $position,
    $box-edge-values
  ) {
    $box-edge-values: _unpack-shorthand($box-edge-values);
    $offsets: (
      top:    nth($box-edge-values, 1),
      right:  nth($box-edge-values, 2),
      bottom: nth($box-edge-values, 3),
      left:   nth($box-edge-values, 4),
    );
  
    position: $position;
  
    @each $offset, $value in $offsets {
      @if _is-length($value) {
        #{$offset}: $value;
      }
    }
  }


///   Triangle
///   .element {
///     &::before {
///       @include triangle("up", 2rem, 1rem, #b25c9c);
///       content: "";
///     }
///   }
///
///   // CSS Output
///   .element::before {
///     border-style: solid;
///     height: 0;
///     width: 0;
///     border-color: transparent transparent #b25c9c;
///     border-width: 0 1rem 1rem;
///     content: "";
///   }

@mixin triangle(
    $direction,
    $width,
    $height,
    $color
  ) {
    @if not index(
      "up" "up-right" "right" "down-right" "down" "down-left" "left" "up-left",
      $direction
    ) {
      @error "Direction must be `up`, `up-right`, `right`, `down-right`, " +
             "`down`, `down-left`, `left` or `up-left`.";
    } @else if not _is-color($color) {
      @error "`#{$color}` is not a valid color for the `$color` argument in " +
             "the `triangle` mixin.";
    } @else {
      border-style: solid;
      height: 0;
      width: 0;
  
      @if $direction == "up" {
        border-color: transparent transparent $color;
        border-width: 0 ($width / 2) $height;
      } @else if $direction == "up-right" {
        border-color: transparent $color transparent transparent;
        border-width: 0 $width $width 0;
      } @else if $direction == "right" {
        border-color: transparent transparent transparent $color;
        border-width: ($height / 2) 0 ($height / 2) $width;
      } @else if $direction == "down-right" {
        border-color: transparent transparent $color;
        border-width: 0 0 $width $width;
      } @else if $direction == "down" {
        border-color: $color transparent transparent;
        border-width: $height ($width / 2) 0;
      } @else if $direction == "down-left" {
        border-color: transparent transparent transparent $color;
        border-width: $width 0 0 $width;
      } @else if $direction == "left" {
        border-color: transparent $color transparent transparent;
        border-width: ($height / 2) $width ($height / 2) 0;
      } @else if $direction == "up-left" {
        border-color: $color transparent transparent;
        border-width: $width $width 0 0;
      }
    }
  }



/// Border Width
///   .element {
///     @include border-width(1em null 20px);
///   }
///
///   // CSS Output
///   .element {
///     border-bottom-width: 20px;
///     border-top-width: 1em;
///   }
///
/// @require {mixin} _directional-property

@mixin border-width($values) {
    @include _directional-property(border, width, $values);
  }


///  Border Styling
///   .element {
///     @include border-style(dashed null solid);
///   }
///
///   // CSS Output
///   .element {
///     border-bottom-style: solid;
///     border-top-style: dashed;
///   }
///
///

@mixin border-style($values) {
    @include _directional-property(border, style, $values);
  }


///  Border setup
///   .element {
///     @include border($size, style,  $color);
///   }
///
///   // CSS Output
///   .element {
///     border-bottom-style: solid;
///     border-top-style: dashed;
///   }
///
///

  @mixin border($size, $style,  $color){
        border: $size  $style $color;
  }


///  Border Radius
///   .element {
///     @include border-radius(radius);
///   }
///
///   // CSS Output
///   .element {
///     border-bottom-style: solid;
///     border-top-style: dashed;
///   }
///
///

@mixin border-radius($radius){
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}



// Transitions
@mixin transition($transitionParam){
  -webkit-transition: $transitionParam;
  -moz-transition: $transitionParam;
  -o-transition: $transitionParam;
  transition: $transitionParam;
}

// Transform
@mixin transform($transformParam){
  -webkit-transform: $transformParam;
  -moz-transform: $transformParam;
  -o-transform: $transformParam;
  transform: $transformParam;
}


// Animation
@mixin animation($animationParam){
  -webkit-animation: $animationParam;
  -moz-animation: $animationParam;
  -o-animation: $animationParam;
  animation: $animationParam;
}


// SlideDown On Click
@keyframes slideDownOnClick {
  0% {
      margin-top: -100px;
      opacity: .25
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}