// Default Colors
$white: #fff;
$black: #000;
$lightBlack: #333;
$lighterBlack: #404040;
$red: #f00;
$lightRed: #ef4b38;
$lighterRed: #f16555;
$blue: #00f;
$blueBg: #1750c2;
$brandBlue: #3977f3;
$whiteThickBlue: #bed7eb;
$whiteBlue: #EBF4FB;
$green: #0f0;
$lightGreen: #53cb67;
$denseGreen: #10cc4f;
$lightWhite: #F6F9F9;
$darkWhite: #d1d3d4;
$darkerWhite:#b6b7bb;;
$grey: #8b8c8e;
$simplegrey: #ebedef;
$lightGrey: #e3e4e5;
$lighterGrey: #d0d4d6;

// Text Colors
$white-text: $white;
$dark-white-text: $darkWhite;
$black-text: $black;
$light-black-text: $lightBlack;
$lighter-black-text: $lighterBlack;
$dark-blue-text: $brandBlue;
$grey-text: $grey;
$red-text: $red;
$light-red-text: $lightRed;
$green-text: $denseGreen;
$light-green-text: $lightGreen;

// Background colors
$white-bg: $white;
$white-light-bg: $lightWhite;
$black-bg: $black;
$grey-bg: $grey;
$light-grey-bg: $lightGrey;
$light-blue-bg: $whiteBlue;
$blue-bg: $blueBg;
$light-green-bg: $lightGreen;
$green-bg: $denseGreen;
$red-bg: $red;

// Border Colors
$grey-border: $grey;
$light-grey-border: $lightGrey;
$light-white-border: $darkWhite;
$light-blue-border: $whiteThickBlue;
$card-border: $lighterGrey;
$green-border: $denseGreen;
$red-border: $red;

// Miscellenous colors