@keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

@-webkit-keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

@-moz-keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

@-ms-keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

.stars,
.twinkling {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.stars {
    background: $black-bg url(../img/stars.png) repeat top center;
    width: 5000px;
    height: 5000px;
    -moz-animation: orbit 500s linear infinite;
    -ms-animation: orbit 500s linear infinite;
    -o-animation: orbit 500s linear infinite;
    -webkit-animation: orbit 500s linear infinite;
    animation: orbit 500s linear infinite;
    z-index: 0;
}

.twinkling {
    background: transparent url(../img/twinkling.png) repeat top center;
    -moz-animation: move-twink-back 200s linear infinite;
    -ms-animation: move-twink-back 200s linear infinite;
    -o-animation: move-twink-back 200s linear infinite;
    -webkit-animation: move-twink-back 200s linear infinite;
    animation: move-twink-back 200s linear infinite;
    z-index: 0;
}

@-webkit-keyframes orbit {
    from {
        transform: rotate(165deg) translateY(600px) translateX(-600px);
    }
    to {
        transform: rotate(165deg) translateY(4000px) translateX(-550px);
    }
}

@-moz-keyframes orbit {
    from {
        transform: rotate(165deg) translateY(600px) translateX(-600px);
    }
    to {
        transform: rotate(165deg) translateY(4000px) translateX(-550px);
    }
}

@-o-keyframes orbit {
    from {
        transform: rotate(165deg) translateY(600px) translateX(-600px);
    }
    to {
        transform: rotate(165deg) translateY(4000px) translateX(-550px);
    }
}

@keyframes orbit {
    from {
        transform: rotate(165deg) translateY(600px) translateX(-600px);
    }
    to {
        transform: rotate(165deg) translateY(4000px) translateX(-550px);
    }
}