 @keyframes selectionSharerPopover-animation {
    0%{
    transform:matrix(0.97,0,0,1,0,12);
    filter:alpha(opacity=0);
    opacity:0
    }
    20%{
    transform:matrix(0.99,0,0,1,0,2);
    filter:alpha(opacity=70);
    opacity:.7
    }
    40%{
    transform:matrix(1,0,0,1,0,-1);
    filter:alpha(opacity=100);
    opacity:1
    }
    70%{
    transform:matrix(1,0,0,1,0,0);
    filter:alpha(opacity=100);
    opacity:1
    }
    100%{
    transform:matrix(1,0,0,1,0,0);
    filter:alpha(opacity=100);
    opacity:1
    }
  }
  
  #selectionSharerPopover {
    display: none;
    position: absolute;
    top: -100px;
    left: -100px;
    z-index: 1010;
  }
  
  #selectionSharerPopover:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -3px;
    left: 50%;
    margin-left: -4px;
  }
  
  #selectionSharerPopover.anim {
    transition: top .075s ease-out;
    animation: selectionSharerPopover-animation 180ms forwards linear;
    -webkit-animation: selectionSharerPopover-animation 180ms forwards linear;
  }
  
  #selectionSharerPopover-inner {
    position:relative;
    overflow: hidden;
    background-color: $white-bg;
    @include box-shadow(0 2px 3px rgba($black, 0.3));
    @include border-radius(rem(4px));
  }
  
    
  .selectionSharer ul {
    padding: 0;
    display: inline;    
  }
  
  .selectionSharer ul li {
    list-style: none;
    background: none;
    margin: 0;
  }
  
  .selectionSharer a.action {
    font-size: rem(12px);  
    display:block;
    padding: rem(10px);
    border: none;
    color: $light-black-text;
  }
  
  .selectionSharer{
    @media screen and (min-width: 1200px) {
      a:hover {
        color: $dark-blue-text;
        .iconCopy{
          background-position: rem(-106px) rem(-204px);
        }
        .iconBook{
          background-position: rem(-121px) rem(-204px);
        }
        .iconSearching{
          background-position: rem(-91px) rem(-204px);
        }
      }
    }
  }
  
  
  #selectionSharerPopunder.fixed {
    transition: bottom 0.5s ease-in-out;
    width: 100%;
    position: fixed;
    left: 0;
    bottom:-50px;
  }
  
  .selectionSharer {
    transition: -webkit-transform 0.6s ease-in-out;
  }
  
  .selectionSharer.moveDown {
    -webkit-transform: translate3d(0,60px,0);
  }
  
  #selectionSharerPopunder {
    position: absolute;
    left: 0;
    width: 100%;
    height: 0px;
    transition: height 0.5s ease-in-out;
    background: #ccc;
    border: none;
    box-shadow: inset 0px 10px 5px -10px rgba(0,0,0,0.5), inset 0px -10px 5px -10px rgba(0,0,0,0.5);
    border-radius: 0;
    overflow: hidden;
  }
  
  #selectionSharerPopunder.show {
    height: 50px;
  }
  
  .selectionSharerPlaceholder {
    height: 1em;
    margin-bottom: -2em;
    transition: height 0.5s ease-in-out;
  }
  
  .selectionSharerPlaceholder.show {
    height: 50px !important;
  }
  
  #selectionSharerPopunder-inner ul {
    overflow: hidden;
    float:right;
    margin: 0px;
  }
  
  #selectionSharerPopunder-inner ul li {
    padding: 5px;
    overflow: hidden;
  }
  
  #selectionSharerPopunder-inner label {
    color: white;
    font-weight: 300;
    line-height: 50px;
    margin: 0px 20px 0px 10px;
  }
  
  #selectionSharerPopunder-inner a {
    width: 30px;
    height: 30px;
    background-size: 30px;
  }
  
  #selectionSharerPopunder-inner a.tweet {
    background-position: 0px 2px;
  }
  
  
.textSelectionIcons{
    margin-right: rem(5px);
}

.showonlyTextSelection{
  .flashCardAction, .searchAction{
    display:none !important;
  }  
}