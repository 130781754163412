/*the container must be positioned relative:*/
.custom-select {
  position: relative;
  font-size: rem(14px);
  font-weight: 600;
  line-height: 1.629;
}
.custom-select select {
  display: none; /*hide original SELECT element:*/
}
.select-selected {
  background-color: $white-bg;
}
/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
  background-color: inherit;
  background-image: url(../img/spritesheet.svg);
  background-position: rem(-58px) rem(-188px);
  @include transform(rotate(180deg));
}
/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  display: inline-block;
  vertical-align: middle;
  background-image: url(../img/spritesheet.svg);
  background-position: rem(-58px) rem(-188px);
  @include transform(rotate(0deg));
}
/*style the items (options), including the selected item:*/
.select-items div,.select-selected {
  color: #404040;
  cursor: pointer;
}
/*style items (options):*/
.select-items {
  position: absolute;
  background-color: $white-bg;
  border: rem(1px) solid $card-border;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  @include box-shadow(rem(1px) rem(5px) rem(15px) rgba($black-bg, 0.125));
}
.select-items div{
  padding: rem(6px) rem(12px);
  font-size: rem(14px);
  border:none;
}
/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}
.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}