.categoriesBlock {
    padding-left: rem(20px);
    padding-top: rem(20px);
    position: relative;

    &.categoryDetailPage {
        top: rem(15px);
        position: absolute;
        z-index: 3;
    }

    @media screen and (max-width:1199px) {
        padding-left: 0;
        padding-top: rem(19px);
        width: 100%;
    }
}

.categoryOption {
    display: inline-block;
    font-family: $secondaryFont;
    @include font(rem(12px), 500, null, 1.12, $white-text);
    letter-spacing: 0.2px;
    text-transform: uppercase;
    padding: rem(4px) rem(14px);
    @include border-radius(rem(25px));
    border: rem(1px) solid transparent;
    @include transition(all ease-in-out 0.35s);

    @media screen and (max-width: 767px) {
        padding: rem(4px) rem(14px);
    }

    &.active {
        background-color: $white-bg;
        color: $dark-blue-text;
        border: rem(1px) solid $light-white-border;
    }

    @media screen and (min-width:1200px) {
        &:hover {
            background-color: $white-bg;
            color: $dark-blue-text;
            border: rem(1px) solid $light-white-border;
        }
    }

    @media screen and (max-width:991px) {
        @include font(rem(12px), 500, null, 1.12, $white-text);
    }
}

.globalSwitching {
    background: $white-bg;
    @include border-radius(rem(3px));
    position: absolute;
    right: rem(44px);
    top: rem(52px);
    padding: 0 rem(6px);

    @media screen and (max-width: 1759px) {
        right: 0;
    }

    @media screen and (max-width: 1199px) {
        top: rem(3px);
        right: rem(67px);
    }

    a {
        display: inline-block;
        @include font(rem(16px), 700, null, 1.12, lighten($black, 80));
        padding: rem(5px) rem(3px);

        &.active {
            .iconMap {
                background-position: rem(-26px) rem(-204px);
            }

            .iconList {
                background-position: rem(-10px) rem(-204px);
            }
        }

        @media screen and (min-width:1200px) {
            &:hover {
                .iconMap {
                    background-position: rem(-26px) rem(-204px);
                }

                .iconList {
                    background-position: rem(-10px) rem(-204px);
                }
            }
        }

        i {
            display: inline-block;
        }
    }
}

// Category Block
.categoryBlock {
    width: 100%;
    height: rem(255px);
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 767px) {
        height: rem(215px);
    }

    .categoryBgImage {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        &.india {
            background-image: url("../img/taj-mahal.png");
        }

        &.currentAffairs {
            background-image: url("../img/current-affairs.jpg");
        }
        &.onthisday{
            background-image: url("../img/onthisday.jpg");
        }
        &.world{
            background-image: url("../img/world.png"); 
        }
        &.person{
            background-image: url("../img/famous-personalties.jpg");
        }
        &.awards{
            background-image: url("../img/awards.jpg"); 
        }
    }

    .categoryInnerListInfo {
        position: relative;
        padding-top: rem(110px);
        height: 100%;
        background: rgba($black-bg, 0.4);

        @media screen and (max-width: 991px) {}

        @media screen and (max-width:767px) {
            padding-top: rem(95px);
        }

        .categoryTitle {
            font-size: rem(38px);
            color: $white-text;
            text-transform: uppercase;
            font-weight: 700;

            @media screen and (max-width: 991px) {
                font-size: rem(30px);
            }

            @media screen and (max-width:767px) {
                font-size: rem(24px);
            }
        }

        .categoryList {
            padding: rem(5px) 0 rem(10px);

            .owl-stage{
                margin: 0 auto;
            }

            .subCatgeoryItem {
                display: inline-block;
                font-family: "Montserrat", sans-serif;
                font-size: rem(13px);
                font-weight: 600;
                line-height: 1.12;
                letter-spacing: 0.2px;
                padding: rem(12px) rem(25px);
                border-radius: 1.5625rem;
                border: 0.0625rem solid transparent;
                color: $light-black-text;
                width: rem(240px);
                text-align: center;
                background: $white-bg;
                @include transition(all ease-in 0.5s);
                &.active{
                    color: $white-text;
                    background: $brandBlue;
                    text-shadow: rem(1px) rem(1px) rgba($black-bg, 0.1);    
                }
                &:hover {
                    @media screen and (min-width: 1200px) {
                        color: $white-text;
                        background: $brandBlue;
                        text-shadow: rem(1px) rem(1px) rgba($black-bg, 0.1);
                    }
                }
            }
        }
    }
}

// Category Top Details
.categoryTopHead {
    font-family: "Montserrat", sans-serif;
    font-size: rem(36px);
    font-weight: 650;
    color: $light-black-text;

    @media screen and (max-width: 767px) {
        font-size: rem(24px);
    }
}