// Map Section
.mapSection {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;

    .globalSwitching {
        position: absolute;
        right: rem(20px);
        top: rem(78px);
        z-index: 10;
    }
}

.mapBlock {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.mapNavigationBlock {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index:11;
    @media screen and (max-width: 991px) {
        display: none;
        z-index: 10;
    
        &.active {
            display: block;
        }
    }
}

.mapMenuDrilldownBlock {
    width: rem(325px);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    padding-top: rem(58px);

    @media screen and (max-width: 991px) {
        width: 100vw;
        top: rem(140px);
    }
}


.drilldownBlock {
    width: 100%;
    height: 100%;
    @include box-shadow(1px 2px 5px rgba($black, 0.35));
    position: absolute;
    top: rem(58px);
    left: rem(-350px);
    background: $white-bg;
    @include transition(cubic-bezier(0.54, 0.31, 0.44, 0.84) all 0.35s);

    ul {
        margin-bottom: 0;
        height: calc(100% - 100px);
        overflow: auto;

        li {
            border-bottom: rem(1px) solid $light-grey-border;

            .mapListItem {
                @include transition(cubic-bezier(0.54, 0.31, 0.44, 0.84) all 0.35s);

                @media screen and (min-width: 1200px) {
                    &:hover {
                        background: #F8F8F8;
                        @include box-shadow(0 0 10px rgba($black, 0.15));
                    }
                }
                @media screen and (max-width: 991px) {
                    .clickableHeader.h6{
                      font-size: rem(15px);
                    }
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        top: 100%;
        left: auto;
        height: 100%;

        ul {
            height: calc(100% - 150px);
            overflow: auto;
        }

        .backtoMainMenu {
            .iconLeftChevron {
                @include transform(rotate(180deg));
            }
        }

        .mapMainListItem {
            .iconRightChevron {
                @include transform(rotate(0deg));
            }
        }
    }

    &.active {
        left: 0;

        @media screen and (max-width: 991px) {
            left: auto;
            height: calc(100vh - 90px);
            top: 0;
            &.secondLevelDrilldownBlock{
                display:none;
            }
        }
    }
}


.mapLevelInformation {
    width: rem(703px);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: $white-bg;
    padding-top: rem(58px);
    padding-left: rem(325px);
    @include box-shadow(1px 2px 5px rgba($black, 0.35));
    .mapArticle{
        max-height: calc(100vh - 60px);
        overflow: auto;
    }

    @media screen and (max-width: 991px) {
        width: 100vw;
        top: rem(58px);
        padding: rem(20px) 0 0;
        z-index: 20;
        height: 100%;
    }
}

.mobileActiveMap{
    &.active{
        .firstLevelDrilldownBlock{
            display: block;
            top: 0;
        }
    }
}

.closeArticleInformation {
    position: absolute;
    right: rem(-36px);
    top: rem(57px);
    padding: rem(6px) rem(10px);
    background: $white-bg;
    z-index: 1;
    box-shadow: rem(3px) rem(2px) rem(5px) rgba($black, 0.3);

    @media screen and (max-width: 991px) {
        width: rem(70px);
        top: 0;
        padding: rem(8px) rem(10px);
        right: rem(-40px);
        background: transparent;
        box-shadow:none;
    }
}

.backtoMainMenu {
    border-bottom: rem(1px) solid $light-grey-border;
}

.toggleMapMenu {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: rem(45px);
    z-index: 20;
    text-align: center;
    padding: rem(8px) rem(15px);
    border-top: rem(1px) solid $brandBlue;
    background: $brandBlue;

    &,
    &:focus,
    &:active:focus,&:visited:focus {
        color: $white-text;
    }

    .iconUpArrow {
        background-position: rem(-58px) rem(-218px);
    }

    &.collapsed {
        .iconUpArrow {
            background-position: rem(-58px) rem(-218px);
            @include transform(rotate(180deg));
        }
    }
}

// Google Map
.popup-tip-anchor {
    position: absolute;
    width: rem(50px);
    height: rem(25px);
}
.popup-bubble-anchor {
    position: absolute;
    width: 100%;
    bottom: rem(15px);
    left: 0;
}

/* The popup bubble itself. */
.popup-bubble-content {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    background-color: $blueBg;
    padding: rem(2px);
    border-radius: rem(8px);
    font-family: sans-serif;
    overflow-y: auto;
    box-shadow: 0px 2px 10px 1px rgba($black-bg, 0.5);
}

.mapMarkers{
    @include transform(scale(0,0));
    @include transition(all ease-in-out 0.5s);
    &.active{
        @include transform(scale(1,1));
    }
}
