button{
    &:hover,&:focus{
        outline:none;
        box-shadow: none;
    }
}
.btn{
    @include border-radius(15px);
    font-size: rem(15px);
    padding: rem(8px) rem(15px);
    &.h7{
        font-size: rem(14px);
        @media screen and (max-width:1200px) {
            font-size: rem(10px);
         }
    }
    &.h8{
        font-size: rem(12px);
        @media screen and (max-width:1200px) {
            font-size: rem(10px);
         }
    }
    &:hover,&:focus,&:visited{
        outline:none !important;
        box-shadow: none !important;
    }
    &[disabled]{
        cursor: not-allowed;
    }
    &.btn-sm{
        font-size: rem(12px);
    }
    &-secondary{
        color:$light-black-text;
        background-color: #ebf4fb;
        border: solid rem(1px) #bfd8ec;
        padding: rem(6px) rem(14px) !important;
        @media screen and (min-width:1200px) {
            &:hover{
                color:$white-text;
                background-color: $blue-bg;
                border: solid rem(1px) $blue-bg;
            }
         }
    }
    &-blank{
        border: solid rem(1px) $white-text;
        border-radius: rem(4px);
        padding: rem(2px) rem(10px) !important;
        @include transition( all ease-in-out 0.15s);
        color: $white-text;
        &:hover{
            background-color: $white-text;
            color: $blue-bg !important;
        }
    }
    &-primary{
        padding: rem(10px) rem(12px);
        border-radius: rem(4px);
        @include box-shadow(0 3px $blue-bg);
        &:hover,&:focus{
           color: $white-text; 
           @include box-shadow(0 3px $blue-bg);
        }
    }
    &.btn-submit{
        &:hover,&:focus{
            color: $white-text; 
         } 
    }
    &-outline-secondary{
        border-radius: rem(4px);
    }
    &-submit{
        border-radius: rem(4px);
        background-color: $brandBlue;
        color: $white-text;
        &:hover,&:focus{
            background-color:$blueBg;
        }
    }
    &.btnGreyRounded{
        border: solid rem(2px) $grey-border;
        border-radius: rem(25px);
        padding: rem(6px) rem(14px) !important;
        @include transition( all ease-in-out 0.15s);
        color: $grey-text;
        background-color: $white-bg;
        &:hover{
            background-color: $white-bg;
        }
    }
    &.btnWhiteRounded{
        border: solid rem(2px) $white-text;
        border-radius: rem(25px);
        padding: rem(6px) rem(14px) !important;
        @include transition( all ease-in-out 0.15s);
        color: $white-text;
        &:hover{
            background-color: transparent;
        }
    }
    &.btnBlueRounded{
        border: solid rem(2px) $brandBlue;
        border-radius: rem(25px);
        padding: rem(6px) rem(18px) !important;
        @include transition( all ease-in-out 0.15s);
        color:  $brandBlue;
        background: $white-bg;
        font-weight: 600;
        &:hover{
            background: $brandBlue;
            color:  $white-text;
        }
    }

}
// Continuous Button 
.btn-block + .btn-block {
    margin-top: rem(14.5px);
}
