.manorDescription{
    padding-top: rem(130px);
    min-height:calc(100vh - 60px);
    @media screen and (max-width:991px) {
        padding-top: rem(117px);
    }    
    &.manorBlogDetail{
        padding-top: rem(85px);
        @media screen and (max-width:1199px) {
            padding-top: rem(100px);
        }
        @media screen and (max-width:767px) {
            padding-top: rem(105px);
        } 
    }
	&.categorySection{
	  padding-top: rem(80px);	  
    }
    &.sectionWithAds{
      padding-top: rem(30px);  
    }
}