body {
    font-family: 'Nunito Sans', sans-serif;
    text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0;
}
h1{
    line-height: rem(20px);
}

.h7 {
    font-size: rem(14px);
}

.h8 {
    font-size: rem(12px);
}

.h6 {
    font-size: rem(16px);
}
.h10 {
    font-size: rem(10px);
}

.h18 {
    font-size: rem(18px);

    @media screen and (max-width: 767px) {
        font-size: rem(16px);
    }
}

.h22 {
    font-size: rem(22px);
}

.h28 {
    font-size: rem(28px);

    @media screen and (max-width: 991px) {
        font-size: rem(24px);
    }

    @media screen and (max-width: 767px) {
        font-size: rem(18px);
    }
}

.h42 {
    font-size: rem(42px);

    @media screen and (max-width: 991px) {
        font-size: rem(36px);
    }

    @media screen and (max-width: 767px) {
        font-size: rem(30px);
    }
}

a {

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &.searchLink {
        text-transform: uppercase;
        padding: rem(6px) rem(18px);
        border: rem(1px) solid $light-grey-border;
        background: $white-light-bg;
        display: inline-block;
        font-size: rem(14px);
        color: $lighter-black-text;
        margin: rem(10px) rem(5px) rem(10px) 0;
        @include border-radius(rem(25px));
        font-weight: 600;

        @media screen and (max-width: 767px) {
            font-size: rem(13px);
            margin: rem(4px) rem(2px) rem(2px) 0;
        }
    }

    &.tagLink {
        @extend a.searchLink;
        font-size: rem(12px);
    }

    &.loginAcLink{
        padding: rem(10px) rem(20px);
        margin-bottom: rem(20px);
        &.facebookLink{
            color: $white-text;
            background: #3b5998;
        }
        &.googleLink{
            color: $white-text;
            background: #ea4335;
        }
    }
    &.sharableLink{
        width:rem(52px);
        height: rem(52px);
        @include border-radius(8px);
        padding: rem(12px);
        display: inline-block;
        &.facebookLink{
            background: #3b5998;
        }
        &.googleLink{
            background: #ea4335;
        }
        &.twitterLink{
            background: #00aced;      
        }
        &.copyLink{
            color: $white-text;
            background: #8b8c8e;
            text-transform: uppercase;
            width: 100%;
        }
    }
    &.selectedText{
        @extend .secondaryElement;
        .notenumber{
            position: absolute;
            top: rem(-15px);
            left:rem(-15px);
            display: none;
            padding: 0 rem(5px);            
            font-size:rem(10px);
            line-height: 14px;
            text-align: center;
            background: #F6F9F9;
            @include box-shadow(rem(2px) rem(2px) rem(10px) rgba($black-bg,0.35));
            font-weight: 700;
            @include border-radius(3px);
        }
        &.active{
            color:$white-text;
            background:$brandBlue;
            .notenumber{
                display:block;
            }
        }
        &.visited{
            @extend .secondaryElement;
            background:#f6f8b6;
            &.active{
                @extend .secondaryElement;
                background:#f6f8b6;
                .notenumber{
                    display:block;
                    background: $brandBlue;
                    color: $white-text;
                }
            }
        }
        position: relative;
    }
}

.secondaryFont {
    font-family: 'Montserrat', sans-serif;
}

// Fixed Container
.fixedContainer {
    position: relative;

    @media screen and (min-width: 1760px) {
        width: rem(1600px);
        padding: 0 rem(45px);
        margin: 0 auto;
    }
}

@media screen and (max-width:991px) {
    .clickableHeader {

        &.h4,
        &.h6,
        h5 {
            font-size: rem(22px);
        }

        &.h5 {
            font-size: rem(16px);
        }
    }
    .container,
    .container-fluid {
       max-width: 100%;
       padding: 0 rem(15px);
    }
}


@media screen and (max-width:767px) {

    .clickableHeader {

        &.h4,
        &.h6,
        h5 {
            font-size: rem(20px);
        }

        &.h5 {
            font-size: rem(18px);
        }
    }
    h4{
        font-size:rem(18px);
    }
}
@media screen and (max-width:575px) {

    .clickableHeader {
        &.h5 {
            font-size: rem(15px);
        }
    }
}


// Listing Items
ol,
ul {
    list-style: none;
    padding: 0;
}

.wh32{
    width: rem(32px) !important;
    height: rem(32px);
}
.wh50{
    width: rem(50px) !important;
    height: rem(50px);
}
.w64{
    width:rem(64px);
}

.blockDetailList {
    li {
        padding-left: rem(25px);
        position: relative;
        margin-bottom: rem(10px);
        &.selectionShareable{
           opacity: 0.9;
        }
        &.secondaryElement{
           opacity: 1;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            content: "";
            position: absolute;
            top: rem(10px);
            left: rem(5px);
            width: rem(7px);
            height: rem(2.5px);
            // @include border-radius(50%);
            background-color: $lighterRed;
        }
    }
}

.decimalList {
    list-style: decimal;
    list-style-position: inside;

    li {
        padding: rem(7px) rem(5px);
    }
}

.privacyList {
	counter-reset: step-counter;
}
.privacyList li:before {
	content: counter(step-counter);
    counter-increment: step-counter;
    position: absolute;
    top:0;
    font-weight: 700;
    color:#333;
}
.privacyList li{
    position: relative;
    padding-top: rem(30px);
    .primaryElement{
        position: absolute;
        top:0;
        left: rem(30px);
    }
}


// Colorable Colors
.blackHeader {
    display: block;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;

    .blackHeadText {
        background: $white-bg;
        color: $lighter-black-text;
        position: relative;
        z-index: 1;
    }

    .blackHeaderText {
        @extend .blackHeadText;
        color: $light-black-text;

        &:before {
            content: "";
            width: 16px;
            height: 16px;
            background-image: url("../img/spritesheet.svg");
            background-position: rem(-28px) rem(-305px);
            position: absolute;
            top: rem(2px);
            left: rem(-25px);
        }

        @media screen and (max-width:991px) {
            padding-left: rem(20px);
            &:before {
                left: 0;
            }
        }
    }

    .moreData {
        position: relative;
        z-index: 1;
        background: $white-bg;
    }

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        width: 100%;
        border-bottom: rem(1px) solid $light-grey-border;
    }
    .headerButton{
        position: relative;
        z-index: 2;
        text-transform: capitalize;
        &:before{
            content:'';
            position: absolute;
            width:rem(10px);
            height:100%;
            background-color: $white-bg;
            left:rem(-12px);
            top: 0;
        }
    }
}

.greyHeader {
    display: block;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;

    .greyHeadText {
        background: $white-bg;
        position: relative;
        z-index: 1;
    }

    .moreData {
        position: relative;
        z-index: 1;
        background: $white-bg;
    }

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        width: 100%;
        border-bottom: rem(1px) solid $light-grey-border;
    }
}

.whiteHeader {
    font-weight: 700;
    color: $white-text;
    display: block;
}

.primaryElement {
    font-weight: 700;
    color: $light-black-text;
}

.redElement {
    font-weight: 800;
    color: $light-red-text;
}

.secondaryElement {
    color: $lighter-black-text;
}

.tertiaryElement {
    color: $grey-text;
}

.blueElement {
    color: $dark-blue-text;
}
.darkBlueElement{
    color: $blueBg;
}

.tertiaryLink {
    color: $grey-text;

    @media screen and (min-width: 1200px) {
        &:hover {
            color: $black-text;
        }
    }
}

.whiteElement {
    color: $white-text;
}

// Bold States
.bold {
    font-weight: 600;
}

.bolder {
    font-weight: 700;
}

.historyheader {
    font-family: $secondaryFont;
    font-weight: 700;
    color: $white-text;
    line-height: 1.12;

    @media screen and (max-width: 767px) {
        font-size: rem(20px);
    }
}
.historyDescription{
    max-height: 100px;
    overflow: hidden;
}

.clickableWhiteElement {
    color: $white-text;

    @media screen and (min-width: 1200px) {
        &:hover {
            color: $white-text;
            text-decoration: underline;
        }
    }
}

.clickableHeader {
    font-family: $secondaryFont;
    font-weight: 700;
    color: $light-black-text;
    line-height: 1.2;

    @media screen and (min-width: 1200px) {
        &:hover {
            color: $black-text;
        }
    }
}

.detailHeader {
    font-family: $secondaryFont;
    font-weight: 700;
    color: $light-black-text;

    @media screen and (max-width: 767px) {
        font-size: rem(24px);
    }
    @media screen and (max-width:575px) {
        font-size: rem(20px);
        margin-bottom: rem(10px);
    }
}

.modaldetailHeader{
    font-family: $secondaryFont;
    font-weight: 700;
    color: $light-black-text;
    @media screen and (max-width: 767px) {
        &.h5 {
            font-size:rem(15.5px);
        }
    }
}

.clickableWhiteHeader {
    color: $white-text;
    font-weight: 700;

    @media screen and (min-width: 1200px) {
        &:hover {
            color: darken($red, 20);
        }
    }
}

.paraGraph {
    @include font(rem(16px), 400, null, 1.5, $lighter-black-text);

    &-short {
        @include font-size(14px);
    }
}

.paraGraphDetails {
    @extend .paraGraph;
    font-size: rem(16px);
    position: relative;
    z-index: 1;
    margin-bottom: 0;

    .inlineTooltipInfo {
        border-bottom: rem(2px) solid $brandBlue;
    }
}

.whiteparaGraph {
    @include font(rem(16px), 400, null, 1.5, lighten($white-text, 50));

    &-short {
        @include font-size(14px);
    }
}


// Fixed Description
.fixedDescription {
    height: rem(68px);
    overflow: hidden;

    @media screen and (max-width:991px) {
        max-height: rem(50px);
    }
}

//Icons
.clickableIcon {
    color: lighten($black, 30);

    @media screen and (min-width: 1200px) {
        &:hover {
            color: lighten($black, 10);
        }
    }
}

.clickableWhiteIcon {
    color: $white-text;

    @media screen and (min-width: 1200px) {
        &:hover {
            color: darken($red, 20);
        }
    }
}

// Video Fluid
.video-fluid {
    width: 100%;
    height: 100%;
}

// Corner Radius
.rounded8 {
    @include border-radius(8px !important);
}

.rounded25 {
    @include border-radius(25px !important);
}

.rightTopLeftBottomRounded50 {
    @include border-radius(0 rem(50px) 0 rem(50px) !important);

    @media screen and (max-width: 1199px) {
        @include border-radius(0 rem(25px) 0 rem(25px) !important);
    }
}

.leftTopRightBottomRounded50 {
    @include border-radius(0 rem(50px) 0 rem(50px) !important);

    @media screen and (max-width: 1199px) {
        @include border-radius(0 rem(25px) 0 rem(25px) !important);
    }
}

.leftTopRightBottomRounded10 {
    @include border-radius(rem(10px) 0 rem(10px) 0 !important);
}
.rightTopRounded10 {
    @include border-radius(rem(10px) 0 rem(10px) 0 !important);
}
.leftTopRightBottomRounded8{
    @include border-radius(rem(8px) 0 rem(8px) 0 !important);
}

// Margin-Settings
.mB40 {
    margin-bottom: 40px;
}
.mB30 {
    margin-bottom: 30px;
}
.mB20 {
    margin-bottom: 20px;
}

.inlineOptions{
    min-width:rem(60px);
}

// Background Colors GradientBG
.blueGradientBg {
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(289deg, #1750c2, #0c2861);
        background-image: -webkit-gradient(linear, left top, left bottom, from(#1750c2), to(#0c2861));
        background-image: -moz-linear-gradient(top, #1750c2, #0c2861);
        opacity: 0.9;
    }

    &.storyBgImg {
        background: url('../img/storyBoard.jpg') center center no-repeat;
        background-size: cover;
    }
}

// Background color
.orangeBg {
    background-color: $lightRed;
}

.whiteHeadBg{
    background-color: $white-bg;
    @include box-shadow(0 rem(1px) rem(5px) rgba(0,0,0,0.015));
}

.bg-info{
    background-color:#23aefe !important;
}


// OverFlowHidden
.overflowHidden {
    overflow: hidden;
}



// Dropdown
.loadMoreCatgories, .memberDrop .dropdown-toggle, .dropdown .dropdown-toggle{
    &:after {
        display: none;
    }
}

.categoryDropdown {
    margin-top: rem(16px);
    border: 1px solid $card-border;

    .categoryOption {
        font-weight: 600;
        color: $lighter-black-text;
        @include border-radius(0);
        border: none;

        @media screen and (min-width: 1200px) {
            &:hover {
                border: none;
                color: $white-text;
                background: $brandBlue;
            }
        }
    }
}

.dropdown-menu-center {
    left: 50%  !important;
    right: auto  !important;
    text-align: left;
    transform: translate(-50%, 0)  !important;
    margin-top: rem(30px);
}

// Accordian
.helpAccordian{
    margin-bottom: rem(30px);
    .card-header{
        background: $white-bg;
        border-top: rem(1px) solid $light-grey-border;
        border-bottom: none;
        padding: rem(15px) rem(20px);
        padding-left: rem(40px);
        &:first-child{
            border:none;
        }
        .card-title{
            @extend .h18;
            .iconAdd{
                background-position: rem(-33px) rem(-502px);
            }
            position: relative;
            i{
                position: absolute;
                left:rem(-30px);
                top:rem(3px);
            }
        }
        &.collapsed{
            .card-title{
                @extend .secondaryElement;
                .iconAdd{
                    background-position: rem(-11px) rem(-502px);
                }
            }   
        }
    }
    .card-body{
        padding: 0 0 rem(15px) rem(40px);
    }
}

.detailAccordian{
    .card-header{
        a{
            position: relative;
            padding-right: rem(20px);
            i{
                position: absolute;
                right: 0;
                @include transition(all ease-in-out 0.5s);
            }
            &[aria-expanded="true"]{
             i{
                @include transform(rotate(360deg));
             }   
            }
        }
    }
}


// Scrollable at Tablet Level to Mobile
.scrollableToMobile {
    @media screen and (max-width: 991px) {
        white-space: nowrap;
        overflow: auto !important;
    }
}


// Row carousel Setup
.rowCarousel {
    &.videosRow{
        @media screen and (max-width: 1199px) {   
            .owl-stage{
                padding-left:rem(15px) !important;
                .item {
                    padding: 0;
                }
              } 
        }  
    }
    @media screen and (max-width: 991px) {
        .owl-stage-outer{
          padding-left: 0;
        }
    }
}

// Video Time Setup
.videoTime {
    position: absolute;
    top: rem(20px);
    right: rem(20px);
    font-size: rem(12px);
    color: $white-text;
    z-index: 2;
    padding: rem(2px) rem(5px);
    background-color: $lightBlack;
    border-radius: 8px;
}

.videoTimeSinglePost {
    position: absolute;
    bottom: rem(20px);
    right: rem(20px);
    font-size: rem(12px);
    color: $white-text;
    z-index: 2;
    padding: rem(2px) rem(5px);
    background-color: $lightBlack;
    border-radius: 8px;
}

// Tabs
.blackOverlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($black, 0.5);
    width: 100%;
    height: 100%;
    display: none;

    &.active {
        display: block;
        z-index: 1030;
    }
}

// Calendar Overlay
.inlineCalendar {
    position: relative;
    @extend .h8;
    @extend  .blueElement;
    line-height: 24px;
    &:after{
        content:'';
        position: absolute;
        width: rem(16px);
        height: rem(16px);
        display: inline-block;
        vertical-align: middle;
        background-image: url("../img/spritesheet.svg");
        background-position: rem(-138px) rem(-206px);
        top: rem(6px);
        left: 0;
        @extend  .blueElement;
    }
    .calendarView {
        padding: rem(3px) 0 rem(3px) rem(30px);
        border:none;
        max-width: 100px;
        line-height: rem(16px);
        @extend  .blueElement;
        &:focus,&:hover{
            box-shadow: none;
            outline: none;
        }
        &::-webkit-input-placeholder{
          color: $brandBlue;  
        }
        &::-moz-input-placeholder{
           color: $brandBlue;
        }
        &::-ms-input-placeholder{
          color: $brandBlue; 
        }
    }
}

.datepicker {
    padding: rem(5px);
}

.bootstrap-datetimepicker-widget table td span,
.bootstrap-datetimepicker-widget table td i {
    margin: rem(2px) rem(1.5px);
    cursor: pointer;
    border-radius: 0.25rem;
}

.bootstrap-datetimepicker-widget table td span:hover,
.bootstrap-datetimepicker-widget table td i:hover {
    background-color: transparent;
}

.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget table th {
    font-size: rem(14px);
}

.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget table td.day {
    height: rem(28px);
    line-height: rem(28px);
    width: rem(28px);
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
    background-color: transparent;
    font-weight: 800;
}

.bootstrap-datetimepicker-widget .picker-switch td span,
.bootstrap-datetimepicker-widget .picker-switch td i {
    height: rem(16px);
    width: rem(16px);
    margin: 0;
}

.bootstrap-datetimepicker-widget a[data-action] {
    padding: rem(10px) 0;
    text-align: center;
}

.bootstrap-datetimepicker-widget .timepicker table td span,
.bootstrap-datetimepicker-widget table td i {
    width: rem(16px);
    height: rem(16px);
    line-height: rem(16px);
}


.bloggedlist{
    li {
        &.question {
            padding-right: rem(20px);
            &::after {
                position: absolute;
                content: '' !important;
                top: 0;
                bottom: auto;
                right: 0;
                background-color: #d0d4d6;
                width: rem(1px);
                height:100%;
            }
        }
    }
}

.alphabetlist{
    max-width:100%;
    overflow: auto;
    white-space: nowrap;
}

.quizLink {
    text-transform: uppercase;
    padding: rem(5px) 0.75rem;
    background: rgba(255, 255, 255, 0.44);
    display: inline-block;
    font-size: rem(12px);
    color: $white-text;
    margin: 0.625rem 0.3125rem 0 0;
    border-radius: 1.5625rem;
    font-weight: 300;

    &:hover {
        color: $white-text;
    }
}

.cardHoverElement {
    .quizLinkLayer {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        z-index: 1;
        transition: all cubic-bezier(0.39, 0.575, 0.565, 1) 0.8s;
        background: rgba($black-bg, 0.35);
    }
}


// Circle Legend
.circleLegend {
    width: rem(10px);
    height: rem(10px);
    margin-right: rem(10px);
    @include border-radius(rem(25px));

    &.circleGreen {
        background-color: $light-green-bg;
    }

    &.circleRed {
        background-color: $red-bg;
    }

    &.circleUnattempted {
        background-color: $grey-bg;
    }
}

// Canvas
canvas {
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    margin: 0;
}


// Line Separator with text center
.lineSeparatorWithText{
    position: relative;
    hr{
        width: 100%;
        position: absolute;
    }
    span{
      background-color:$white-bg;
      padding:rem(10px);
      position: relative;
      z-index: 1;
      top: rem(2px);
      @extend .h8;
      @extend .tertiaryElement;
      text-transform: uppercase;
    }
}


// Accordian

.accordion{
     .collapsed{
         &.blueElement{
            @extend .primaryElement;  
         }
     }
}

// pagination aplhalist
.alphabetlist{
    a{
        @include border-radius(rem(4px));
        border: rem(1px) solid transparent;
        padding: rem(1px) rem(6px);
        margin-bottom: rem(10px);
        color: #8b8c8e;
        font-weight: 700;
        margin-right: rem(6px) !important;
        &.active{
            border-color: $card-border;
            @extend .blueElement;
            background-color: $lightWhite;
        }
    }
}


canvas{
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}


/****
Subscription Parameters
***/
.subscriptionDate{
    .subscriptionDateValue, .subscriptionDateLabel{
        position: absolute;
        left: 0;
        z-index: 2;
        width: 100%;
        text-align: center;
    }
    .subscriptionDateValue{
        top: 25%;
    }
    .subscriptionDateLabel{
        top: 50%;
    }
}