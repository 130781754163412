.questionsList{
    position: relative;
    .questionBlock{
        padding: rem(10px) rem(15px) rem(40px);
        position: relative;
        .questionListNumber{
            padding: rem(10px) 0;
            @extend .tertiaryElement;
            @extend .h8; 
        }
    }
    .articleQuizNavigation{
      position: absolute;
      top: rem(15px);
      right: rem(15px);
    }
    .questionCounter{
        position: absolute;
        left: 48%;
        right:auto;
        bottom: rem(40px);
        text-align: center;
    }
}
.articleQuizList{
    >li{
        display:none;
        &.currentView{
            display: block;
        }
    }
}
.questionArea{
    margin-bottom: rem(20px);
}

.answerCard{
    position: relative;
    margin-bottom: rem(15px);
    .radioAnswer{
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        &:checked ~ .answerLabel{
            &:before{
                width:100%;
                background-color: darken($red,15);
            }
            color: $white;
            &.correctAnswer{
                &:before{
                    background-color: $light-green-bg;  
                }
            }
        }
        &.displayAnswer  ~ .answerLabel{
            &:before{
                width:100%;
                background-color: darken($red,15);
            }
            color: $white;
            &.correctAnswer{
                &:before{
                    background-color: $light-green-bg;  
                }
            }
        }
    }
    .answerLabel{
        margin-bottom: 0;
        display: block;
        background-color: #f6f9f9;
        border: solid 1px #e3e4e5;
        padding: rem(8px) rem(10px);
        cursor: pointer;
        position: relative;
        overflow: hidden;
        span{
            position: relative;
            z-index: 1;
        }
        &:before{
            content: '';
            position: absolute;
            top:0;
            left:0;
            width:0;
            height:100%;
            @include transition(all linear 0.5s);
        }
    }
}

.testSubmitBlock{
    height: rem(240px);
}
.reviewMockAnswers{
    &:hover,&:focus{
        .iconBlueEye {
            background-position: rem(-250px) rem(-431px);
        }    
    }
}
