.responsiveMenu {
    position: relative;
    overflow: visible;

    ul#menuList {
        max-width: rem(1020px);
        overflow: hidden;
        margin: 0;

        @media screen and (min-width: 1200px) and (max-width: 1440px) {
            max-width: rem(930px);
        }
        @media screen and (max-width: 1199px) {
            max-width: 100%;
            overflow: auto;
        }
    }

    #menuList>li {
        display: block;
        float: left;
        white-space: nowrap;
        margin-right: rem(10px);
    }

    .overFlowMenudropdown {
        position: absolute;
        right: rem(-50px);
        top: 0;
        overflow: visible;
        display:none !important;
        &.active{
            display:block !important;
        }
    }

    .overFlowMenudropdown {
        .dropdown-menu {
            @include box-shadow(0 rem(6px) rem(10px) rgba(0, 0, 0, 0.2));
            border: none;
        }
    }
}

.overFlowMenudropdown {
    .dropdown {
        .dropdown-toggle {
            position: relative;
            width: rem(36px);
            height: rem(24px);
            display: inline-block;
            vertical-align: middle;
            color: transparent;
            border: 0;
            outline: none;
            cursor: pointer;
            z-index: 5;
            right: 0;

            &:before {
                content: '';
                position: absolute;
                width: rem(35px);
                height: rem(44px);
                background: $white-bg;
                @include border-radius(rem(8px) rem(8px) 0 0);
                display: none;
                right: 0;
            }

            &:after {
                display: none;
            }

            .iconMore {
                position: relative;
                margin: rem(4px) rem(10px);
            }
        }

        &.show {
            .dropdown-toggle {
                &:before {
                    display: block;
                }

                .iconMore {
                    background-position: rem(-41px) rem(-204px);
                }
            }
        }
    }
}

#submenuList {
    width: 13.125rem;
    padding: 0 .625rem;
    background: #fff;
    border-radius: 0 0 .5rem .5rem;
    margin-top: rem(12px);

    @media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
        margin-top: rem(95px);
    }

    li {
        display: block;
        width: 100%;
        margin: rem(10px) 0;
    }

    .categoryOption {
        border: none;
        color: $light-black-text;
        padding: rem(6px) rem(15px);

        @media screen and (min-width: 1200px) {
            &:hover {
                color: $white-text;
                border: none;
                background: $brandBlue;
            }
        }
    }
}