.cardslider {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.cardslider__cards,
.cardslider__dots-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cardslider__cards {
  width: 80%;
  height: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  transform-style: preserve-3d;
}

.cardslider__dots-nav {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.cardslider__dot {
  display: inline-block;
  margin-right: 8px;
}

.cardslider__dot-btn {
  position: relative;
  border-radius: 50%;
  border: 2px solid white;
  width: 10px;
  height: 10px;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  padding: 0;
}

.cardslider__dot-btn::before {
  content: ' ';
  position: absolute;
  display: block;
  background: white;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transition: all .5s;
  -ms-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}

.cardslider__dot--active .cardslider__dot-btn::before {
  -ms-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
}

.cardslider__card {
  background: #d7d7d7;
  display: block;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  will-change: transform, opacity, background;
  transition: background 1s ease-out, opacity 1s;
}

.cardslider__card--transitions {
  transition: background 1s ease-out, transform 1s ease-out, opacity 1s;
}

.cardslider__card--invisible {
  opacity: 0;
}

.cardslider--direction-up .cardslider__card {
  transform: scale(0.9) translate3d(0, 14.61%, 2px);
}

.cardslider--direction-up .cardslider__card--index-0 {
  transform: translate3d(0, 0, 4px);
}

.cardslider--direction-up .cardslider__card--index-1 {
  transform: scale(0.95) translate3d(0, 7.31%, 3px);
}

.cardslider--direction-up .cardslider__card--index-2 {
  transform: scale(0.9) translate3d(0, 14.61%, 2px);
}

.cardslider--direction-up .cardslider__card--out {
  transform: translate3d(0, 150%, 3px);
}

.cardslider--sortback-up {
  animation: sortback-up 2s;
}

.cardslider--sortfront-up {
  animation: sortfront-up 0.67s;
}

.cardslider--direction-down .cardslider__card {
  transform: translate3d(0, 0, 4px);
}

.cardslider--direction-down .cardslider__card--index-0 {
  transform: translate3d(0, 0, 4px);
}

.cardslider--direction-down .cardslider__card--index-1 {
  transform: translate3d(0, 0, 4px);
}

.cardslider--direction-down .cardslider__card--out {
  transform: translate3d(0, 150%, 4px);
}

.cardslider--sortback-down {
  animation: sortback-down 0.67s;
}

.cardslider--sortfront-down {
  animation: sortfront-down 0.67s;
}

.cardslider--direction-right .cardslider__card {
  transform: scale(0.8) translate3d(-17.25%, 0, 0);
}

.cardslider--direction-right .cardslider__card--index-0 {
  transform: translate3d(0, 0, 4px);
}

.cardslider--direction-right .cardslider__card--index-1 {
  transform: scale(0.95) translate3d(-4.31%, 0, 3px);
}

.cardslider--direction-right .cardslider__card--index-2 {
  transform: scale(0.9) translate3d(-8.61%, 0, 2px);
}

.cardslider--direction-right .cardslider__card--index-3 {
  transform: scale(0.85) translate3d(-12.91%, 0, 1px);
}

.cardslider--direction-right .cardslider__card--out {
  transform: translate3d(150%, 0, 4px);
}

.cardslider--sortback-right {
  animation: sortback-right 0.67s;
}

.cardslider--sortfront-right {
  animation: sortfront-right 0.67s;
}

.cardslider--direction-left .cardslider__card {
  transform: scale(0.8) translate3d(13.91%, 16.91%, 1px);
}

.cardslider--direction-left .cardslider__card--index-0 {
  transform: translate3d(0, 0, 4px);
}

.cardslider--direction-left .cardslider__card--index-1 {
  transform: scale(0.95) translate3d(4.41%, 5.31%, 3px);
}

.cardslider--direction-left .cardslider__card--index-2 {
  transform: scale(0.9) translate3d(8.95%, 10.95%, 2px);
}

.cardslider--direction-left .cardslider__card--index-3 {
  transform: scale(0.85) translate3d(13.91%, 16.91%, 1px);
}

.cardslider--direction-left .cardslider__card--out {
  transform: translate3d(-150%, -150%, 4px);
}

.cardslider--sortback-left {
  animation: sortback-left 1s;
}

.cardslider--sortfront-left {
  animation: sortfront-left 1s;
}

.cardslider__direction-nav {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.cardslider__nav-next:active,
.cardslider__nav-prev:active {
  -ms-transform: scale(0.8);
  transform: scale(0.8);
}

.cardslider__nav-next {
  position: relative;
  left: 30px;
}

.cardslider__nav-next:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  background-image: url("../img/spritesheet.svg");
  background-position: -5.625rem -24.375rem;
}

@media screen and (min-width: 1200px) {
  .cardslider__nav-next:hover:after {
    background-position: -5.625rem -25.625rem;
  }
}

.cardslider__nav-prev {
  position: relative;
  right: 30px;
}

.cardslider__nav-prev:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  background-image: url("../img/spritesheet.svg");
  background-position: -5.625rem -24.375rem;
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media screen and (min-width: 1200px) {
  .cardslider__nav-prev:hover:after {
    background-position: -5.625rem -25.625rem;
  }
}

@keyframes sortback-down {
  0% {
    transform: translate3d(0, 0, 4px) scale(1);
  }

  50% {
    transform: translate3d(0, -150%, 4px) scale(1);
  }

  60% {
    transform: translate3d(0, -150%, 0) scale(0.8);
  }

  100% {
    transform: translate3d(0, -150%, 4px) scale(1);
  }
}

@keyframes sortfront-down {
  0% {
    transform: translate3d(0, 0, 0) scale(0.8);
  }

  50% {
    transform: translate3d(0, -150%, 0) scale(0.8);
  }

  60% {
    transform: translate3d(0, -150%, 4px) scale(1);
  }

  100% {
    transform: translate3d(0, -150%, 4px) scale(1);
  }
}

@keyframes sortback-up {
  0% {
    transform: translate3d(0, 0, 4px) scale(1);
  }

  50% {
    transform: translate3d(0, -150%, 4px) scale(1);
  }

  60% {
    transform: translate3d(0, -150%, 0) scale(0.8);
  }

  100% {
    transform: translate3d(0, 0, 0) scale(0.8);
  }
}

@keyframes sortfront-up {
  0% {
    transform: translate3d(0, 0, 0) scale(0.8);
  }

  50% {
    transform: translate3d(0, -150%, 0) scale(0.8);
  }

  60% {
    transform: translate3d(0, -150%, 4px) scale(1);
  }

  100% {
    transform: translate3d(0, 0, 4px) scale(1);
  }
}

@keyframes sortback-left {
  0% {
    transform: translate3d(0, 0, 4px) scale(1);
  }

  50% {
    transform: translate3d(150%, 0, 4px) scale(1);
  }

  60% {
    transform: translate3d(150%, 0, 0) scale(0.8);
  }

  100% {
    transform: translate3d(0, 0, 0) scale(0.8);
  }
}

@keyframes sortfront-left {
  0% {
    transform: translate3d(0, 0, 0) scale(0.8);
  }

  50% {
    transform: translate3d(150%, 0, 0) scale(0.8);
  }

  60% {
    transform: translate3d(150%, 0, 4px) scale(1);
  }

  100% {
    transform: translate3d(0, 0, 4px) scale(1);
  }
}

@keyframes sortback-right {
  0% {
    transform: translate3d(0, 0, 4px) scale(1);
  }

  50% {
    transform: translate3d(150%, 0, 4px) scale(1);
  }

  60% {
    transform: translate3d(150%, 0, 0) scale(0.8);
  }

  100% {
    transform: translate3d(0, 0, 0) scale(0.8);
  }
}

@keyframes sortfront-right {
  0% {
    transform: translate3d(0, 0, 0) scale(0.8);
  }

  50% {
    transform: translate3d(150%, 0, 0) scale(0.8);
  }

  60% {
    transform: translate3d(150%, 0, 4px) scale(1);
  }

  100% {
    transform: translate3d(0, 0, 4px) scale(1);
  }
}

.cardslider__nav-next:active,
.cardslider__nav-prev:active {
  -ms-transform: scale(1);
  transform: scale(1);
}

/** Card Slider  Styles **/
.cardNested {
  height: rem(380px);

  &.notesNested {
    height: 240px !important;
  }

  .cardslider__cards {
    width: 100%;
    height: 85%;
    top: 0;
    left: 0;

    .cardslider__card {
      background: transparent;
    }
  }

  .card {
    margin-bottom: 0;
  }

  .cardslider__direction-nav {
    width: rem(50px);
    height: rem(20px);
    right: rem(30px);
    bottom: rem(68px);
    z-index: 20;

    @media screen and (max-width: 1199px) {
      bottom: rem(55px);
    }
    @media screen and (max-width:991px) {
      bottom: rem(45px);
    }
    @media screen and (max-width:750px) {
      bottom: rem(40px);
    }
    @media screen and (max-width:640px) {
      bottom: rem(70px);
    }
    @media screen and (max-width:640px) and (orientation: landscape) {
      bottom: rem(60px);
    }
    @media screen and (max-width:500px) {
      bottom: rem(55px);
    }
    @media screen and (max-width:320px) {
      bottom: rem(80px);
    }

    .cardslider__nav-next,
    .cardslider__nav-prev {
      width: rem(20px);
      height: rem(20px);
      border: none;
      display: inline-block;
      background: transparent;
      cursor: pointer;
      text-indent: -9999px;
      padding: 0;
      position: absolute;
    }

    .cardslider__nav-next {
      left: auto;
      right: 0
    }

    .cardslider__nav-prev {
      right: auto;
      left: 0
    }
  }

  &.questionsNested {
    height: rem(450px);

    .cardslider__direction-nav {
      width: calc(100% - 50px);
      right: auto;
      left: auto;
      bottom: rem(60px);
      margin: 0 rem(25px);

      .cardslider__nav-next {
        left: calc(100% - 13px);
      }
    }
  }
}

.cardslider__nav-next {
  position: relative;
  left: 30px;

  &:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    background-image: url("../img/spritesheet.svg");
    background-position: rem(-90px) rem(-390px);
  }

  @media screen and (min-width: 1200px) {
    &:hover:after {
      background-position: rem(-90px) rem(-410px);
    }
  }
}

.cardslider__nav-prev {
  position: relative;
  right: 30px;

  &:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    background-image: url("../img/spritesheet.svg");
    background-position: rem(-90px) rem(-390px);
    @include transform(rotate(180deg));
  }

  @media screen and (min-width: 1200px) {
    &:hover:after {
      background-position: rem(-90px) rem(-410px);
    }
  }
}



// Slider Card
.sliderCard {
  position: relative;

  &.actualDiv {
    visibility: hidden;
  }

  .slideImage {
    position: relative;
    width: 100%;
    z-index: 2;

    a {
      display: block;

      img {
        border: rem(1px) solid $card-border;
      }
    }

    @media screen and (max-width:767px) {
      a {
        overflow: hidden;
        @include border-radius(8px);
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }

    .ph-picture {
      @media screen and (min-width: 768px) and (max-width:1199px) {
        height: rem(133px);
      }
    }
  }

  .slidableCard {
    position: relative;
    z-index: 1;
    top: rem(-40px);
    height: rem(250px);
    padding: rem(42px) rem(24px);
    display: flex;

    @media screen and (max-width: 767px) {
      padding: rem(42px) rem(15px);
      height:100%;
    }
    .cardContentBlock{
      max-height: 160px;
      overflow: hidden;
      @media screen and (max-width: 767px) {
        max-height: 160px;
      }
      @media screen and (max-width: 500px) {
        max-height: 132px;
      }
    }

    .bookMarkIt {
      position: absolute;
      bottom: rem(18px);

      @media screen and (max-width: 767px) {
        bottom: rem(16px);
      }

      @media screen and (max-width:350px) {
        bottom: rem(16px);
      }
    }
  }
}

// Flash Card
.flashCard {
  position: relative;

  .flashCardNumber {
    position: absolute;
    bottom: rem(10px);
  }

  .flashCardShortTitle {
    position: absolute;
    top: rem(10px);
  }
}

.flashDropdown {
  position: absolute;
  top: rem(10px);
  right: rem(15px);

  .dropdown-toggle {
    &:after {
      display: none;
    }
  }
}

.flashCardslider {
  @extend .cardNested;
  width: 100%;

  .cardslider__cards {
    width: 100%;
    height: 80%;
  }

  .card {
    height: 100%;
  }

  .cardslider__direction-nav {
    width: 100%;
    height: rem(40px);
    bottom: 0;
    right: 0;
    padding: rem(5px) 0;
    background: $white-bg;
    z-index: 10;

    .cardslider__nav-next {
      width: rem(100px);
      height: 70%;
      position: absolute;
      left: auto;
      right: 0;
      @include border-radius(rem(25px));
      background: $white-bg;
      border: solid rem(2px) $brandBlue;
      text-indent: 0;
      color: $brandBlue;
      font-weight: 600;
      font-size: rem(14px);

      &:after {
        display: none;
      }
    }

    .cardslider__nav-prev {
      width: rem(100px);
      height: 70%;
      position: absolute;
      left: 0;
      right: auto;
      @include border-radius(rem(25px));
      background: $white-bg;
      border: solid rem(2px) $brandBlue;
      text-indent: 0;
      color: $brandBlue;
      font-weight: 600;
      font-size: rem(14px);

      &:after {
        display: none;
      }
    }
  }

  .flashContentProgress {
    position: absolute;
    bottom: rem(14px);
    width: calc(100% - 240px);
    height: rem(12px);
    overflow: hidden;
    @include border-radius(25px);
    left: rem(120px);
    z-index: 11;
  }
}

// Quiz Slider Block
.quizSliderBlock {
  height: rem(500px);

  @media screen and (max-width: 767px) {
    height: rem(550px);
  }

  &.postQuizCardSlider {
    height: rem(650px);

    @media screen and (max-width: 767px) {
      height: rem(580px);
    }
  }

  .cardslider__cards {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .cardslider__card {
      background: transparent;
      position: absolute;
    }
  }

  .cardslider__direction-nav {
    display: none;
  }
}

.postQuizBlock {
  .quizSliderBlock {
    height: rem(620px);

    @media screen and (max-width: 767px) {
      height: rem(650px);
    }

    .cardslider__direction-nav {
      width: 100%;
      display: block;
      bottom: calc(50% - 30px);
      left: 0;

      .cardslider__nav-prev {
        position: absolute;
        left: rem(1px);
        right: auto;
        text-indent: -999px;
        overflow: hidden;
        width: rem(24px);
        height: rem(40px);
        padding: 0;
        border: none;
        @include box-shadow(0 0 rem(10px) rem(2px) rgba(0, 0, 0, 0.15));
        background-color: $white-bg;
        cursor: pointer;

        &:after {
          content: "";
          position: absolute;
          width: rem(16px);
          height: rem(16px);
          left: rem(5.5px);
          top: rem(12px);
          background-image: url("../img/spritesheet.svg");
          background-position: -3.625rem -11.875rem;
          @include transform(rotate(-90deg));
        }
      }

      .cardslider__nav-next {
        position: absolute;
        left: auto;
        right: rem(1px);
        text-indent: -999px;
        overflow: hidden;
        width: rem(24px);
        height: rem(40px);
        padding: 0;
        border: none;
        @include box-shadow(0 0 rem(10px) rem(2px) rgba(0, 0, 0, 0.15));
        background-color: $white-bg;
        cursor: pointer;

        &:after {
          content: "";
          position: absolute;
          width: rem(16px);
          height: rem(16px);
          left: rem(4.5px);
          top: rem(12px);
          background-image: url("../img/spritesheet.svg");
          background-position: -3.625rem -11.875rem;
          @include transform(rotate(90deg));
        }
      }
    }

    .reviewInformation {
      height: 120px;
      overflow: auto;
      @extend .h7;
      @extend .secondaryElement;
      text-align: left;
      padding: rem(15px);
      background-color: $white-light-bg;
    }

    .skipQuestion {
      display: none;
    }
  }
}

// My Notes
.gotoArticle {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 50;
}
.detailedCardNotes{
  position: relative;
  border-top:rem(1px) solid $light-grey-border;
  background-color: $light-blue-bg;
}

.cardNested.notesNested .cardslider__direction-nav{
  width: calc(100% - 25px);

  right: auto;
  left: auto;
  bottom: 0;
  margin: 0 1.5625rem;
}

.cardNested.notesNested .cardslider__direction-nav .cardslider__nav-prev{
  right: 30px;
  left: auto;
}