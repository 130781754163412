.nav-tabs {
    border: none;
    overflow-y: hidden;
    overflow-x: visible;
    .nav-link {
        padding: rem(10px) 0 rem(15px);
        margin-right: rem(30px);
        border: none;
        border-bottom: rem(0px) solid transparent;
        opacity: 0.5;
        position: relative;
        font-size:rem(16px);
        @media screen and (max-width:767px) {
            font-size: rem(14px);
            margin-right: rem(15px);
        }
        @media screen and (max-width:350px) {
            font-size: rem(12px);
            margin-right: rem(10px);
        }
        &:before {
            content: '';
            width: 100%;
            height: rem(4px);
            background: transparent;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        &.active {
            opacity: 1;
            color: $light-black-text;
            &:before {
                background: $brandBlue;
            }
        }

        @media screen and (min-width:1200px) {
            &:hover {
                opacity: 1;
                color: $light-black-text;
                &:before {
                    background: $brandBlue;
                }
            }
        }
    }

    &.searchNavTabs {
        @media screen and (max-width: 1199px) {
            width: calc(100% - 80px);
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;

            @media screen and (max-width: 767px) {
                width: calc(100% - 30px);
            }
        }

        .nav-link {
            overflow: visible;
            height:45px;
            @media screen and (max-width: 991px) {
                font-size: rem(18px);
            }

            @media screen and (max-width:767px) {
                font-size: rem(16px);
            }
        }
    }

    &.bookMarksNavTabs {
        width: 100%;
        overflow-y: hidden;
        overflow-x: visible;
        white-space: nowrap;
    }
}

.navigationTabs {
    margin-left: rem(78px);
}

.tab-content {
    padding-top: rem(30px);
}

.profileTabSection {
    @media screen and (min-width: 1200px) {
        overflow: hidden;
        .nav-item{
            border-bottom: rem(1px) solid $light-grey-border;
            margin-bottom: 0;
            &:last-child{
                border-bottom: none;
            }
        }
        .nav-link {
            border-bottom: none;
            font-weight: 700;
            padding: rem(15px) rem(15px);

            &.active {
                &:before {
                    width: rem(4px);
                    height: calc(100% - 18px);
                    right: 0;
                    left: auto;
                    top: rem(8px);
                }
            }
        }
    }
}

.communityTab.nav-tabs {
    .nav-link {
        font-size: rem(14px);
        @extend .tertiaryElement;
        @extend .bolder;
        opacity: 1;
        height: rem(40px);

        &:hover {
            border-bottom: rem(1px) solid transparent;
        }

        &.active {
            @extend .blueElement;
            border-bottom: rem(1px) solid transparent;

            &:before {
                content: '';
                position: absolute;
                bottom: rem(-16px);
                left: calc(50% - 10px);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 10px 10px 10px;
                border-color: transparent transparent #d0d4d6 transparent;
                background: transparent;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: rem(-17px);
                left: calc(50% - 10px);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 10px 10px 10px;
                border-color: transparent transparent #fff transparent;
                background: transparent;
            }
        }
    }
}

.textEditorContent {
    .tab-content {
        padding: 0;
        margin-right: rem(5px);
        margin-right: rem(5px);
    }
}

@media screen and (min-width: 1200px){
    .profileTabSection .nav-link {
        border-bottom: none;
        font-weight: 700;
        padding: 0.9375rem 0.9375rem;
        &:hover{
          &:before{
              background: transparent;
          }
          &.active{
            &:before{
                background: $brandBlue;
            }
          }
        }
    }
}

.tab-pane{
    .detailHeader{
        height:50px;
        overflow: hidden;
    }
}