.stacktable { width: 100%; }
.st-head-row { padding-top: 1em; }
.st-head-row.st-head-row-main { font-size: 1.5em; padding-top: 0; }
.st-key { width: 49%; text-align: right; padding-right: 1%; }
.st-val { width: 49%; padding-left: 1%; }



/* RESPONSIVE EXAMPLE */

@media screen and (max-width: 767px) {
    .responsiveTable{
        border-top: none;
        border-bottom: none;
         thead {
            display: none;
          }
        
          tr {
            display: table-row;
            position: relative;
            width: calc(100vw - 45vw);
            &:nth-child(odd){
             background: $white-bg;
            }
            border-bottom: rem(2px) solid rgba($lighterBlack,0.45);
            &:last-child{
                border-bottom: none;
            }
          }
        
          td {
            display: block;
            border-left: none !important;
            border-right: none !important;
            border-bottom: none !important;
            position: relative;
            padding-left: 45vw !important;
            &:first-child{
                border-top: none !important;
            }
            &:last-child{
                border-bottom: none;
            }
          }
          td:before {
            content: attr(data-label);
            display: table-cell;
            font-weight: 600;
            text-align: left;
            padding: rem(7px) rem(10px);
            position: absolute;
            width: 45%;
            left: 0;
            top: 0;
          }
    }
  }




// Table
.table-bordered.table-warning{
    background-color: #feffee;
    border: rem(1px) solid $card-border;
    th,td{
        border: rem(1px) solid $card-border;
        padding: rem(7px) rem(10px);
        @extend .secondaryElement;
    }
    thead{
        th{
            font-size:rem(12px);
            font-weight: 700;
            background-color: #f3f5d5;
        }
    }
    td{
        font-size:rem(14px);
    }
    tbody{
        th{
            font-size:rem(14px);
        }
    }
}

.defaultTable{
    border: rem(1px) solid $card-border;
    tr{
        th,td{
            border-bottom: rem(1px) solid $card-border;
        }
    }
    thead{        
        th{
            border-bottom:none;
            @extend .tertiaryElement;
            font-size:rem(12px);
        }
    }
    tbody{
        td{
            font-size:rem(14px);
        }
    }
}