// Icon Source
.icon {
    display: inline-block;
    vertical-align: middle;
    background-image: url("../img/spritesheet.svg");
    background-repeat: no-repeat;
}

// Icon Sizes
.icon12 {
    width: rem(12px);
    height: rem(12px);
}

.icon14 {
    width: rem(14px);
    height: rem(14px);
}

.icon16 {
    width: rem(16px);
    height: rem(16px);
}

.icon18 {
    width: rem(18px);
    height: rem(18px);
}

.icon20 {
    width: rem(20px);
    height: rem(20px);
}

.icon25 {
    width: rem(25px);
    height: rem(25px);
}

.icon28 {
    width: rem(28px);
    height: rem(28px);
}


// Icons List
// Search Icons
.iconSearch {
    background-position: rem(-70px) rem(-431px);
}

.iconWhiteSearch {
    background-position: rem(-70px) rem(-431px);
}
.iconGreySearch {
    background-position: rem(-70px) rem(-391px);
}

// Alert Icons
.iconAlert {
    background-position: rem(-10px) rem(-431px);
}

.iconWhiteAlert {
    background-position: rem(-50px) rem(-431px);
}

// Map Switch Icons
.iconMap {
    background-position: rem(-26px) rem(-188px);
}

.iconWhiteMap {
    background-position: rem(-50px) rem(-431px);
}

// List Switch Icons
.iconList {
    background-position: rem(-10px) rem(-188px);
}

.iconWhiteList {
    background-position: rem(-50px) rem(-431px);
}

// Bars icon
.iconBars {
    background-position: rem(-10px) rem(-97px);
}

// SwitchList icon
.iconSwitchList {
    background-position: rem(-25px) rem(-97px);
}

// SwitchList icon
.iconBookmark {
    background-position: rem(-10px) rem(-390px);

    &.active {
        background-position: rem(-10px) rem(-410px);
    }

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-10px) rem(-410px);
        }
    }
}

// SwitchList icon
.iconSiteMap {
    background-position: rem(-30px) rem(-390px);

    &.active {
        background-position: rem(-30px) rem(-410px);
    }

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-30px) rem(-410px);
        }
    }
}

.iconWatchlist {
    background-position: rem(-190px) rem(-390px);

    &.active {
        background-position: rem(-190px) rem(-410px);
    }

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-190px) rem(-410px);
        }
    }
}

// SwitchList icon
.iconVideo {
    background-position: rem(-93px) rem(-681px);
}

// SwitchList icon
.iconGallery {
    background-position: rem(-10px) rem(-681px);
}

.iconBlueGallery {
    background-position: rem(-10px) rem(-653px);
}

// App Icons
.iconApple {
    background-position: rem(-66px) rem(-626px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-66px) rem(-654px);
        }
    }
}

.iconAndroid {
    background-position: rem(-38px) rem(-626px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-38px) rem(-654px);
        }
    }
}

// Share Icons
.iconFacebook {
    background-position: rem(-122px) rem(-626px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-122px) rem(-654px);
        }
    }
}

.iconTwitter {
    background-position: rem(-149px) rem(-626px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-149px) rem(-654px);
        }
    }
}

.iconWhiteFacebook {
    background-position: rem(-122px) rem(-680px);
}

.iconWhiteTwitter {
    background-position: rem(-149px) rem(-680px);
}

.iconWhiteGoogle {
    background-position: rem(-235px) rem(-680px);
}

.iconWhiteCopyLink {
    background-position: rem(-205px) rem(-680px);
}

.iconPlay {
    background-position: rem(-178px) rem(-626px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-178px) rem(-654px);
        }
    }
}

.iconThumbsUp {
    background-position: rem(-39px) rem(-97px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-39px) rem(-111px);
        }
    }
}

.iconThumbsDown {
    background-position: rem(-39px) rem(-97px);
    @include transform(rotate(180deg));

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-39px) rem(-111px);
        }
    }
}

// Arrows
.iconLeftArrow {
    background-position: rem(-90px) rem(-390px);
    @include transform(rotate(180deg));

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-90px) rem(-410px);
        }
    }
}

.iconRightArrow {
    background-position: rem(-90px) rem(-390px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-90px) rem(-410px);
        }
    }
}

.iconShare {
    background-position: rem(-170px) rem(-390px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-170px) rem(-410px);
        }
    }
}

.iconWhiteShare {
    background-position: rem(-170px) rem(-432px);
}

.iconWriteBook {
    background-position: rem(-130px) rem(-390px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-130px) rem(-410px);
        }
    }
}

.iconReadOut {
    background-position: rem(-110px) rem(-390px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-110px) rem(-410px);
        }
    }
}

.iconBookMark {
    background-position: rem(-89px) rem(-390px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-89px) rem(-410px);
        }
    }
}

.iconMore {
    vertical-align: top;
    background-position: rem(-41px) rem(-220px);

    &.active {
        background-position: rem(-41px) rem(-204px);
    }
}

.iconBlackMore {
    background-position: rem(-42px) rem(-188px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-42px) rem(-204px);
        }
    }
}

.iconCopy {
    background-position: rem(-106px) rem(-188px);

    &.active {
        background-position: rem(-106px) rem(-204px);
    }

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-106px) rem(-204px);
        }
    }
}

.iconBook {
    background-position: rem(-121px) rem(-188px);

    &.active {
        background-position: rem(-121px) rem(-204px);
    }

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-121px) rem(-204px);
        }
    }
}

.iconSearching {
    background-position: rem(-91px) rem(-188px);

    &.active {
        background-position: rem(-91px) rem(-204px);
    }

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-91px) rem(-204px);
        }
    }
}

.iconLeftChevron {
    background-position: rem(-58px) rem(-190px);
    @include transform(rotate(-90deg));

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-58px) rem(-206px);
        }
    }
}

.iconRightChevron {
    background-position: rem(-58px) rem(-190px);
    @include transform(rotate(90deg));

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-58px) rem(-206px);
        }
    }
}

.iconUpArrow {
    background-position: rem(-58px) rem(-188px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-58px) rem(-204px);
        }
    }
}

.iconDownArrow {
    background-position: rem(-58px) rem(-188px);
    @include transform(rotate(180deg));

    &.active {
        background-position: rem(-58px) rem(-204px);
        @include transform(rotate(180deg));
    }

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-58px) rem(-204px);
            @include transform(rotate(180deg));
        }
    }
}

.iconHamburger {
    background-position: rem(-45px) rem(-322px);
}
.iconBlueHamburger {
    background-position: rem(-45px) rem(-302px);
}

.iconCalendar {
    background-position: rem(-138px) rem(-204px);
}

.iconClose {
    background-position: rem(-74px) rem(-188px);

    &.active {
        background-position: rem(-74px) rem(-204px);
    }

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-74px) rem(-204px);
        }
    }
}


.iconWhiteTimer {
    background-position: rem(-230px) rem(-390px);
}

.iconRedTimer {
    background-position: rem(-230px) rem(-410px);
}

.iconWhiteEye {
    background-position: rem(-250px) rem(-431px);
}

.iconBlueEye {
    background-position: rem(-250px) rem(-411px);
}

.iconWatchEye {
    background-position: rem(-250px) rem(-391px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-250px) rem(-411px);
        }
    }
}

.iconFlashVideo {
    background-position: rem(-211px) rem(-391px);
}

.iconQuizView {
    background-position: rem(-291px) rem(-391px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-291px) rem(-411px);
        }
    }
}

.iconSortSearch {
    background-position: rem(-9px) rem(-284px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-9px) rem(-302px);
        }
    }
}

.iconTextBold {
    background-position: rem(-153px) rem(-284px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-153px) rem(-302px);
        }
    }
}

.iconTextItalic {
    background-position: rem(-173px) rem(-284px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-173px) rem(-302px);
        }
    }
}

.iconTextUnderline {
    background-position: rem(-188px) rem(-284px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-188px) rem(-302px);
        }
    }
}

.iconTextOrderlist {
    background-position: rem(-226px) rem(-285px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-226px) rem(-303px);
        }
    }
}

.iconTextUnorderlist {
    background-position: rem(-208px) rem(-284px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-208px) rem(-302px);
        }
    }
}

.iconTextStrike {
    background-position: rem(-246px) rem(-285px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-246px) rem(-303px);
        }
    }
}

.iconAttachmentLink {
    background-position: rem(-81px) rem(-284px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-81px) rem(-302px);
        }
    }
}
.iconEditorLink {
    background-position: rem(-135px) rem(-284px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-135px) rem(-302px);
        }
    }
}

.iconImageLink {
    background-position: rem(-99px) rem(-284px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-99px) rem(-302px);
        }
    }
}

.iconTextEditor {
    background-position: rem(-116px) rem(-284px);

    @media screen and (min-width: 1200px) {
        &:hover {
            background-position: rem(-116px) rem(-302px);
        }
    }
}

.iconAdd{
    background-position: rem(-11px) rem(-502px);
    // background-position: -33px -502px;
}

.iconReset{
    background-position: rem(-53px) rem(-97px);
}
.iconComputer{
    background-image: url("../img/computer.svg"); 
    background-size: contain;   
}
.iconNotebook{
    background-image: url("../img/notebook.svg");  
    background-size: contain;  
}