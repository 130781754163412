.form-group {
    margin-bottom: rem(20px);

    .form-area {
        background-color: #f6f9f9;
        border: solid rem(1px) #e3e4e5;
        min-height: 50px;
    }

    .form-control {
        font-size: rem(14px);

        &:focus,
        &:hover {
            box-shadow: none;
            outline: none;
        }
    }

    &.searchGroup {
        position: relative;

        &::after {
            content: '';
            background-image: url("../img/spritesheet.svg");
            position: absolute;
            right: rem(18px);
            top: rem(22px);
            width: rem(20px);
            height: rem(20px);
            background-position: rem(-70px) rem(-390px);
        }

        .bootstrap-tagsinput {
            font-size: rem(20px);
            min-height: rem(56px);
            @extend .secondaryFont;
            @include box-shadow(0 3px 6px 0 rgba(0, 0, 0, 0.16));
            width: 100%;
            padding: rem(10px) rem(45px) rem(3px) rem(15px);
            @include border-radius(rem(8px));

            @media screen and (max-width: 1199px) {
                font-size: rem(18px);
                min-height: rem(40px);
            }

            @media screen and (max-width:991px) {
                font-size: rem(16px);
                min-height: rem(36px);
            }

            @media screen and (max-width:768px) {
                font-size: rem(14px);
                min-height: rem(30px);
            }

            &:focus,
            &:hover {
                box-shadow: none;
                outline: none;
            }

            &::-webkit-input-placeholder {
                color: $grey-text;
                opacity: 1;
            }

            &::-moz-placeholder {
                color: $grey-text;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: $grey-text;
                opacity: 1;
            }

            &::-ms-input-placeholder {
                color: $grey-text;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: $grey-text;
                opacity: 1;
            }

            &::placeholder {
                color: $grey-text;
                opacity: 1;
            }
        }
    }

    &.passwordGroup {
        position: relative;

        .form-control {
            padding-right: rem(60px);
        }

        .forgotPassword {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
            padding: rem(10px);
        }
    }

    &.inputFileGroup{
        position: relative;
        .inputForFile{
            opacity: 0;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    &.inlineCalendar{
        &:after{
            top:rem(13px);
            left:rem(10px);
        }
        .calendarView{
            max-width: 100%;
            border: rem(1px) solid #ced4da;
            padding-left: rem(35px);
        }
    }
}

.captchaGroup {
    position: relative;

    .captchaPlaceholder {
        position: relative;
    }

    .captchaReset {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        padding: rem(5px) rem(25px);
    }
}

.form-control {
    &::-webkit-input-placeholder {
        color: rgba($black, 0.4);
    }

    &::-moz-placeholder {
        color: rgba($black, 0.4);
    }

    &:-ms-input-placeholder {
        color: rgba($black, 0.4);
    }

    &::-ms-input-placeholder {
        color: rgba($black, 0.4);
    }

    &:-ms-input-placeholder {
        color: rgba($black, 0.4);
    }

    &::placeholder {
        color: rgba($black, 0.4);
    }
}

// Special Inputs
input {
    &.mobileSearch {
        width: calc(100% - 100px);
        border: none;
        background: $light-grey-bg;
    }
}

.input-group {
    input.form-control {
        border: none;
        background: transparent;
        padding: 0;
        padding-left: rem(10px);
    }

    background: $white-bg;
    border: rem(1px) solid $light-grey-border;
    @include border-radius(4px);
    margin-top: rem(10px);

}

// Search Form
.searchForm {
    width: calc(100% - 30px);
    margin-right: rem(20px);
}

.bootstrap-tagsinput {
    border: rem(1px) solid $card-border;

    .tag {
        background: $light-grey-bg;
        @include border-radius(rem(25px));
        color: $lighter-black-text;
        padding: rem(7px) rem(45px) rem(7px) rem(14px);
        position: relative;
        display: inline-block;
        margin-bottom: rem(10px);
        margin-right: rem(10px);
        text-transform: lowercase;

        [data-role="remove"] {
            position: absolute;
            right: rem(15px);
            top: rem(15px);

            @media screen and (max-width: 1199px) {
                top: rem(13px);
            }

            @media screen and (max-width:768px) {
                top: rem(12px);
            }

            @media screen and (max-width:767px) {
                top: rem(10px);
            }

            &:after {
                content: '';
                background-image: url("../img/spritesheet.svg");
                position: absolute;
                right: 0;
                top: 0;
                width: rem(16px);
                height: rem(16px);
                background-position: rem(-74px) rem(-188px);
            }
        }
    }

    input {
        margin: rem(10px) 0;
        border: none;

        &:hover,
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}

// Label Less Form
.labelessForm {
    .form-group {

        .form-control,
        .custom-select,
        .custom-select .select-selected {
            background-color: $simplegrey;
            -webkit-appearance: none;
            -ms-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }

        .bootstrap-tagsinput {
            background-color: $simplegrey;
            @include border-radius(4px);
            padding: 0 rem(12px);
            margin: 0;
            font-size: rem(15px);

            .tag {
                display: inline-block;
                margin-top: rem(10px);
                background-color: rgba($black, 0.15);

                [data-role="remove"] {
                    position: absolute;
                    right: rem(10px);
                    top: rem(10px);
                }
            }

            input {
                background: transparent;

                &::-webkit-input-placeholder {
                    color: rgba($black, 0.4);
                }

                &::-moz-placeholder {
                    color: rgba($black, 0.4);
                }

                &:-ms-input-placeholder {
                    color: rgba($black, 0.4);
                }

                &::-ms-input-placeholder {
                    color: rgba($black, 0.4);
                }

                &:-ms-input-placeholder {
                    color: rgba($black, 0.4);
                }

                &::placeholder {
                    color: rgba($black, 0.4);
                }
            }
        }
        .input-group{
            background-color: $simplegrey;
        }
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}



// Text Editor
.createThreadSection {
    @media screen and (max-width: 767px) {
        .container {
            width: 100%;
            margin: 0;
            padding: 0;

            .col-sm-3 {
                padding: 0;
            }
        }
    }
}

.textEditorContainer {
    border: rem(1px) solid $card-border;
    height: 100%;
    position: relative;
    z-index: 5;

    @media screen and (max-width: 767px) {
        border: none;
        border-top: rem(1px) solid $card-border;
        border-bottom: rem(1px) solid $card-border;
        @include border-radius(rem(0px) !important);
    }

    &.communityInlineEditor {
        height: auto;

        @media screen and (max-width: 767px) {
            width: calc(100% + 48px);
            margin-left: rem(-24px);
            .communityTab{
                border-bottom: rem(1px) solid $light-grey-border;
                padding-bottom: rem(10px);
                .nav-link.active:before{
                    bottom: rem(-10px);
                }
                .nav-link.active:after{
                    bottom: rem(-11px);
                }
            }
            .textEditorControlBlock{
                border:none;
            }
        }
    }

    .textEditorControlBar {
        padding: rem(15px) rem(20px);
        position: relative;

        @media screen and (max-width: 767px) {
            width: 100%;
            margin: 0;
            padding: 0;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
        }

        .custom-select {
            border: none;
            max-width: rem(180px);
            padding-left: 0;

            @media screen and (max-width: 767px) {
                max-width: 100%;
                margin-top: rem(10px);
                @include border-radius(0 !important);

                .select-selected {
                    padding: 0 rem(10px)
                }
            }
        }

        .textEditorControllers {
            .dropdown-menu {
                left: rem(10px) !important;
                margin-top: rem(5px);

                input {
                    border-width: rem(1px);
                    border-color: transparent transparent $light-grey-border transparent;
                    margin: rem(5px) rem(15px);

                    &:focus,
                    &:hover {
                        outline: none;
                        box-shadow: none;
                    }
                }

                a {
                    padding: rem(5px) rem(15px);
                    display: block;
                }
            }
        }

        .textEditorSubmission {
            @media screen and (max-width: 767px) {
                .btn {
                    font-size: rem(12px);
                }
            }
        }
    }

    .textEditorControlBlock {
        @media screen and (max-width: 767px) {
            border-bottom: rem(1px) solid $light-grey-border;
            padding: rem(10px);
        }
    }

    .textEditorContent {
        border-top: rem(1px) solid $light-grey-border;
        padding: rem(25px) rem(20px);

        @media screen and (max-width: 767px) {
            padding: rem(10px);
        }

        textarea.form-control {
            background: transparent;
        }

        .form-group .form-control {
            font-size: rem(13px);

            &.formTitle {
                font-size: rem(28px);
                padding-right: 0;
                padding-left: 0;
                background: $white-bg;

                @media screen and (max-width: 767px) {
                    font-size: rem(20px);
                }
            }
        }
    }

}

// Auto Complete Options
.autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;
  }
  .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
    @include box-shadow(0 3px 6px 0 rgba(0, 0, 0, 0.16));
    width: calc(100% - 76px);
  }
  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: $white-bg; 
    border-bottom: 1px solid #d4d4d4; 
  }
  .autocomplete-items div:hover {
    background-color: #e9e9e9; 
  }
  .autocomplete-active {
    background-color: DodgerBlue !important; 
    color: $white-bg; 
  }

  // Image Upload
  .imageUploadBlock{
      position: relative;
      max-height:rem(200px);
      overflow: hidden;
      .uploadInput{
          display: none;
      }
      .imageUploadTrigger{
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          label{
            width: 100%;
            margin: 0;
            padding: rem(5px) rem(10px);
            color: rgba($white-text,0.85);
            background: rgba($black,0.5);
            font-size: rem(13px);
            text-align: center;
            border-radius: 0 0 rem(8px) rem(8px);
            cursor: pointer;
          }
      }
  }