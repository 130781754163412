.commentingBlock{
   // border-top: rem(1px) solid lighten($black,80);
    padding: rem(40px) rem(15px) rem(30px) ;
    @media screen and (max-width:991px) {
        padding: rem(30px) rem(15px);
    }
    @media screen and (max-width:767px) {
        padding:rem(10px) 0;
    }
    .commentInformation{
        font-size: rem(12px);
        color: lighten($lighter-black-text,10);
    }
    .commentOptions{
        li{
            a{
                font-size: rem(11px);
                text-transform: uppercase;
                margin: 0 rem(4px);
            }
        }
    }
}

.commentedImage{
    max-width: rem(24px);
}