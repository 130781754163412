
.checkboxRadioContainer {
    display: block;
    position: relative;
    padding-left: rem(35px);
    margin-bottom: rem(12px);
    margin-right: rem(12px);
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: rem(14px);
    font-weight: 600;
    line-height: 1.629;
    color: #404040;
  }
  
  .checkboxRadioContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  // Custom CheckBox
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: rem(20px);
    width: rem(20px);
    background-color: #eee;
  }
  
  .checkboxRadioContainer:hover input ~ .checkmark {
    background-color: $white-bg;
  }
  
  .checkboxRadioContainer input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .checkboxRadioContainer input:checked ~ .checkmark:after {
    display: block;
  }
  
  .checkboxRadioContainer .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }


  // Custom Radio

.radiomark {
    position: absolute;
    top: 0;
    left: 0;
    height: rem(20px);
    width: rem(20px);
    border-radius: 50%;
    border: rem(2px) solid $card-border;
  }
  
  .checkboxRadioContainer:hover input ~ .radiomark {
    background-color: $white-bg;
  }
  
  .checkboxRadioContainer input:checked ~ .radiomark {
    background-color: $white-bg;
    border-color: $brandBlue;
  }
  
  .radiomark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .checkboxRadioContainer input:checked ~ .radiomark:after {
    display: block;
  }
  
  .checkboxRadioContainer .radiomark:after {
    top: rem(4px);
    left: rem(4px);
    width: rem(8px);
    height: rem(8px);
    border-radius: 50%;
    background: $brandBlue;
  }