header {
    padding: rem(8px) rem(24px);
    position: relative;
    @include transition(cubic-bezier(0.54, 0.31, 0.44, 0.84) all 0.35s);

    @media screen and (max-width: 1199px) {
        @include transition(cubic-bezier(0.54, 0.31, 0.44, 0.84) all 0.15s);
    }


    @media screen and (max-width: 1279px) {
        padding: rem(10px) rem(15px);
    }

    @media screen and (max-width:1199px) {
        padding: rem(10px) rem(15px) rem(5px);
    }

    .logoWithHamburger {
        width: rem(150px);
        margin-right: rem(15px);

        @media screen and (max-width: 1199px) {
            margin-right: rem(3px);
        }
    }

    &.fixed-top {
        position: fixed;
        top: 0;
    }

    &.headerUp {
        &.articleDetailedHead {
            top: 0;

            &.headerWithAds {
                top: 0;
            }
        }

        &.mainHeader {
            top: 0;

            &.headerWithAds {
                top: 0;
            }

            @media screen and (min-width: 1200px) {
                .headerNavbar .navbar-brand .manoramaLogo {
                    width: 100%;
                }
            }
        }
    }

    &.headerDown {
        @include transition(cubic-bezier(0.54, 0.31, 0.44, 0.84) all 0.5s);

        @media screen and (max-width: 1199px) {
            @include transition(cubic-bezier(0.54, 0.31, 0.44, 0.84) all 0.15s);
        }

        &.articleDetailedHead {
            top: rem(-58px);

            &.headerWithAds {
                top: 0;
                &.fixed-top{
                    top: rem(-58px);
                }
            }

            @media screen and (max-width:1199px) {
                top: rem(-42px);

                &.headerWithAds {
                    top: 0;
                    &.fixed-top{
                        top: rem(-42px);
                    }
                }
            }
        }

        &.fixedHead {
            top: 0;

            .headerNavbar .navbar-brand {
                width: rem(100px);
            }
        }

        &.mainHeader {
            top: rem(-50px);

            &.headerWithAds {
                top: 0;
                &.fixed-top{
                    top: rem(-50px); 
                }
            }

            @media screen and (max-width: 1199px) {
                top: rem(-50px);

                &.headerWithAds {
                    top: 0;
                    &.fixed-top{
                        top: rem(-50px); 
                    }
                }
            }

            @media screen and (min-width:1200px) {
                .headerNavbar .navbar-brand .manoramaLogo {
                    width: rem(100px);
                    margin-top: rem(50px);
                    &.headerWithAds {
                        width: rem(100px);
                        margin-top: rem(0px);
                        &.fixed-top{
                            margin-top: rem(50px);
                        }
                    }
                }
                &.headerWithAds {
                    .headerNavbar .navbar-brand .manoramaLogo {
                        width: 100%;
                        margin-top: rem(0px);
                    }
                    &.fixed-top{
                        .headerNavbar .navbar-brand .manoramaLogo {
                            width: rem(100px);
                            margin-top: rem(50px);
                        }
                    }
                }

            }
        }
    }
}

.headerBg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba(#3D7DEB, 0.75);
    }
}

.mainHeader {
    height: rem(100px);
}

.articleDetailedHead {
    height: rem(54px);

    @media screen and (max-width: 1199px) {
        height: rem(80px);
    }

    .navbar-nav,
    .globalSwitching {
        display: none;
    }

    .headerNavbar {
        margin-bottom: 0;

        .logoWithHamburger {
            width: rem(100px);
            margin-right: rem(15px);

            .navbar-brand {
                width: 100%;
                top: rem(-16px);

                img {
                    width: 100%;
                }
            }

            @media screen and (max-width:1199px) {
                width: rem(120px);

                .navbar-brand {
                    top: 0;
                }
            }
        }

        .categoriesBlock {
            padding-top: rem(7px);
        }
    }

    @media screen and (max-width:991px) {
        display: none;

        &.articlewithhumburger {
            height: auto;
            display: block;

            .logoWithHamburger,
            .headerBg,
            .categoriesBlock {
                display: none !important;
            }
        }
    }
}

.fixedHead {
    height: rem(58px);

    .headerNavbar {
        margin-bottom: 0;

        .logoWithHamburger {
            width: rem(100px);
            margin-right: rem(15px);

            .navbar-brand .manoramaLogo {
                width: 100%;
                margin-top: rem(3px);
            }

            @media screen and (max-width:1199px) {
                width: rem(150px);
            }

            @media screen and (max-width:991px) {
                .navbar-brand .manoramaLogo {
                    width: 6.25rem;
                    top: 0;
                }
            }
        }
    }

    .categoriesBlock {
        padding-top: rem(10px);
        display: none;
    }
}

.headerNavbar {
    position: relative;
    margin: 0;

    .navbar-brand {
        margin: 0;
        display: block;
        margin-right: auto;
        width: rem(140px);

        .manoramaLogo {
            @include transition(all linear 0.5s);

            @media screen and (max-width: 1199px) {
                width: rem(100px);
            }
        }

        &.detailNavBrand {
            top: 0;
            width: rem(80px);
        }

        @media screen and (max-width:1199px) {
            @include transition(all linear 0s);
            left: 0;
            width: rem(100px);
            position: relative;
        }
    }
}

.navbar-expand-xl {

    .navbar-collapse,
    .learnWithCategories,
    .logoWithHamburger,
    .avatar {
        position: relative;
        z-index: 1;
    }

    @media screen and (max-width:1199px) {
        .navbar-collapse {
            position: fixed;
            width: rem(250px);
            height: 100%;
            z-index: 1041;
            top: 0;
            background-color: $white-bg;
            overflow-x: hidden;
            transition: 0.5s;
            @include border-radius(0 rem(8px) rem(8px) 0);
            @include box-shadow(rem(1px) rem(2px) rem(5px) rgba($black-bg, 0.5));

            &:not(.show) {
                left: rem(-255px);
                display: block;
            }

            &.show {
                left: 0;
            }
        }
    }

    .navbar-nav {
        text-transform: capitalize;

        &.navbar-links {
            .nav-item {
                .nav-link {
                    @include font(rem(16px), null, null, 1.12, $white-text);
                    letter-spacing: rem(0.5px);
                    position: relative;
                    opacity: 0.7;
                    padding: rem(7px) 0;

                    &:after {
                        content: '';
                        width: 100%;
                        height: rem(4px);
                        background-color: $white-bg;
                        position: absolute;
                        bottom: rem(-3px);
                        left: -100%;
                        display: none;
                        @include transition(all 0.2s ease-in-out);
                        @include border-radius(0 0 rem(2px) rem(2px));

                        @media screen and (max-width: 991px) {
                            display: none
                        }
                    }

                    &.active {
                        color: $white-text;
                        opacity: 1;

                        &:after {
                            display: block;
                            left: 0;
                            @include transition(all 0.2s ease-in-out);

                            @media screen and (max-width: 1199px) {
                                display: none
                            }
                        }
                    }

                    @media screen and (min-width:1200px) {
                        &:hover {
                            opacity: 1;
                        }
                    }

                    @media screen and (max-width:1199px) {
                        padding: rem(15px) rem(20px);
                        text-decoration: none;
                        font-size: rem(16px);
                        transition: 0.3s;
                        color: $light-black-text;
                        font-weight: 700;

                        &.active {
                            color: $brandBlue;
                            border: none;
                        }
                    }
                }
            }
        }
    }

    .nav-flex-icons {
        margin: rem(5px) rem(65px) rem(5px) 0;

        @media screen and (max-width: 1199px) {
            margin: 0;
            position: absolute;
            right: 0;
            top: rem(-30px);
        }

        .nav-link {
            padding: 0;
            @include font(rem(12px), 400, null, rem(18px), $white-text);
            letter-spacing: rem(0.2px);
        }

        .list-inline-item {
            margin: 0 rem(15px);

            @media screen and (max-width: 1199px) {
                margin: 0 rem(5px);
                display: inline-block;
                vertical-align: middle;

                &:last-child {
                    margin-right: 0;
                }
            }

            &:last-child {
                margin-right: 0;

                &:first-child {
                    margin-right: rem(15px);
                }
            }
        }
    }

    .detailNavbarCollapse {
        .navbar-nav {
            &.navbar-links {
                .nav-item {
                    .nav-link {
                        &:after {
                            bottom: rem(-13px);
                        }
                    }
                }
            }
        }
    }
}

.navbar-toggler {
    padding: 0 0.75rem;
}

.alertLink {
    position: relative;

    .circularIndicator {
        position: absolute;
        top: 0;
        right: rem(3px);
        @include border-radius(50%);
        width: rem(8px);
        height: rem(8px);
        background-color: $lightRed;
        opacity: .25;
        @include animation(blink 2s infinite);

        @media screen and (max-width: 1199px) {
            top: rem(2px);
            right: rem(2px);
        }
    }
}

@keyframes blink {
    0% {
        transform: scale(1);
        opacity: .25
    }

    50% {
        transform: scale (1, 5);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: .25;
    }
}


// Sidebar Logo
.sidebarLogo {
    margin: rem(15px) rem(20px);

    .closebtn {
        position: relative;
        font-size: rem(30px);
        color: $brandBlue;
    }

    .manaramaLogo {
        width: rem(80px);
    }
}

.userInformation {
    display: inline-block;

    @media screen and (max-width: 1199px) {
        position: relative;
        right: 0;
        display: block;
        margin: rem(10px) rem(20px);
        border-top: 1px solid $light-grey-border;
        display: block;
        border-bottom: 1px solid $light-grey-border;
        padding: rem(20px) 0;
    }

    .iconDownArrow {
        top: rem(42px);
    }

    .userImage {
        width: rem(30px);
        display: inline-block;

        img {
            width: 100%;
        }

        @media screen and (max-width:1199px) {
            width: rem(50px);
            margin-right: rem(10px);
        }
    }

    .userinfo {
        padding: rem(10px) rem(15px);
        border-bottom: rem(1px) solid $light-grey-border;
    }

    .userDataDetails {
        padding: rem(10px) rem(15px) 0;

        ul {
            padding-bottom: rem(10px);
            margin: 0;
            border-bottom: rem(1px) solid $light-grey-border;

            a {
                padding: rem(5px) 0;
            }
        }

        &:last-child {
            padding-bottom: rem(10px);
        }
    }

    .userData {
        &:after {
            display: none;
        }
    }

    &.show {
        .iconDownArrow {
            @include transform(rotate(0deg));
        }
    }

    .dropdown-menu {
        @include box-shadow(0 3px 6px 0 rgba($black-bg, 0.31));
        border: none;

        @media screen and (max-width: 1199px) {
            width: 100%;
            height: rem(250px);
            @include box-shadow(0 3px 6px 0 transparent);

            .userDataDetails,
            .userinfo {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
}


// Topmenu
.topMenu {
    width: 100%;
    position: relative;

    .userInformation {
        position: absolute;
        right: rem(-214px);
        top: rem(3px);
        display: inline-block;

        @media screen and (max-width: 1199px) {
            position: relative;
            right: 0;
            display: block;
            margin: rem(10px) rem(20px);
            border-top: 1px solid $light-grey-border;
            display: block;
            border-bottom: 1px solid $light-grey-border;
            padding: rem(20px) 0;
        }

        .iconDownArrow {
            position: absolute;
            right: 0;
            top: rem(42px);
        }

        .userImage {
            width: rem(30px);
            display: inline-block;

            img {
                width: 100%;
            }

            @media screen and (max-width:1199px) {
                width: rem(50px);
                margin-right: rem(10px);
            }
        }

        .userinfo {
            padding: rem(10px) rem(15px);
            border-bottom: rem(1px) solid $light-grey-border;
        }

        .userDataDetails {
            padding: rem(10px) rem(15px) 0;

            ul {
                padding-bottom: rem(10px);
                margin: 0;
                border-bottom: rem(1px) solid $light-grey-border;

                a {
                    padding: rem(5px) 0;
                }
            }

            &:last-child {
                padding-bottom: rem(10px);
            }
        }

        .userData {
            &:after {
                display: none;
            }
        }

        &.show {
            .iconDownArrow {
                @include transform(rotate(0deg));
            }
        }

        .dropdown-menu {
            @include box-shadow(0 3px 6px 0 rgba($black-bg, 0.31));
            border: none;

            @media screen and (max-width: 1199px) {
                width: 100%;
                height: rem(250px);
                @include box-shadow(0 3px 6px 0 transparent);

                .userDataDetails,
                .userinfo {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }
        }
    }
}

.preLogin {
    .nav-flex-icons {
        margin-right: rem(125px);

        @media screen and (max-width: 1199px) {
            margin-right: 0;
        }
    }

    .userInformation {
        right: rem(-216px);

        @media screen and (max-width: 1199px) {
            right: 0;
        }

        a {
            margin-left: rem(12px);
            color: $white-text;
            font-size: rem(12px);

            @media screen and (max-width: 1199px) {
                font-sizre: rem(14px);
                color: $black-text;

                &.btn-blank {
                    border: rem(1px) solid $black-bg;
                }
            }
        }
    }
}

.menuBlock {
    width: calc(100% - 218px);

    @media screen and (max-width: 1199px) {
        width: 100%;
    }
}