.blogDetailNav {
    width: rem(132px);
    position: absolute;
    left: rem(-152px);
    padding-top: rem(124px);
    height: 100%;
    background: $white-bg;
    @include transition(all ease-in-out 0.5s);

    @media screen and (max-width: 1199px) {
        left: rem(-30px);
        padding-top: rem(90px);

    }

    @media screen and (max-width: 991px) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 50;
        width: 100%;
        padding: rem(10px) rem(15px);
        height: rem(90px);
        padding: 0;
    }

    .manorFloatingNavigation {
        z-index: 1000;
        @include transition(all ease-in-out 0.5s);

        &.is_stuck {
            right: auto;
            left: auto;

            @media screen and (min-width: 992px) {
                padding-top: rem(90px);
            }

            @media screen and (min-width:1200px) {
                padding-top: rem(70px);
            }
        }

        .articleView {
            display: none;

            .iconBars {
                margin-right: rem(10px);
            }

            @include box-shadow(rem(-1px) rem(1px) rem(5px) rgba(0, 0, 0, 0.2) inset);
            background: $white-light-bg;

            @media screen and (max-width:991px) {
                display: block;
            }
        }

        @media screen and (max-width:991px) {
            display: block;
            margin-top: 0;
            right: auto;
            left: 0;
            max-width: 100%;
            width: 100%;
            padding: 0;

            button {
                display: none;
            }
        }
    }
}

.blogNavList {
    li {
        border-top: rem(1px) solid $light-grey-border;

        &:last-child {
            border-bottom: rem(1px) solid $light-grey-border;
            margin-bottom: rem(10px);
        }

        .blogArticleOptions {
            margin-left: rem(10px);

            li {
                border: none;

                a {
                    padding: 0;
                }
            }
        }

        a {
            display: block;
            padding: rem(12px) rem(10px);
            @include font(rem(12px), 400, null, 1.12, $light-black-text);

            &.active {
                color: $dark-blue-text;
            }
        }
    }

    @media screen and (max-width:991px) {
        background: $white-bg;
        top: 0;

        &.active {
            display: block;
            @include animation(slideDownOnClick ease-in-out 0.35s);
        }

        li {
            border: none;

            a {
                padding: rem(12px) rem(30px);

                &.navLinks {
                    display: none;

                    &.activeNavlink {
                        display: block;
                    }
                }
            }
        }
    }
}

.articleNavigationLogo {
    max-width: rem(90px);
    width: 100%;
    display: none;

    @media screen and (max-width: 991px) {
        display: inline-block;
    }
}

.blogViewOtherCards {
    width: rem(260px);

    .cardHoverElement {
        height: rem(195px);

        @media screen and (max-width: 1199px) {
            height: 100%;
        }
    }

    @media screen and (max-width:1199px) {
        .articlesList ul li {
            height: 100%;
        }
    }

    @media screen and (min-width: 992px) and (max-width: 1199px) {
        width: 100%;
        padding: 0 rem(15px);
        position: relative;
        z-index: 1000;
        background: #fff;
    }

    @media screen and (max-width:991px) {
        width: 100%;
        padding: 0;
        position: relative;
        z-index: 1;
    }
}

.blogDetailCard {
    width: rem(640px);
    margin: 0 rem(30px);
    padding: 0 rem(10px);
    position: relative;
    top: rem(-15px);

    @media screen and (max-width: 1199px) {
        width: calc(100% - 15px);
        padding: 0 rem(15px);
        padding-left: rem(120px);
    }

    @media screen and (max-width:991px) {
        width: calc(100% + 30px);
        padding: 0 rem(15px);
        margin: 0 rem(-15px);
        z-index: 10;
    }

    // Text Selection color
    p {
        &::-moz-selection {
            /* Code for Firefox */
            color: $white-text;
            background: $brandBlue;
        }

        &::selection {
            color: $white-text;
            background: $brandBlue;
        }
    }
}

// Reference Links
.referenesBlock {
    .secondaryElement {
        opacity: 0.9;
    }
}

// Blog Details Block
.blockDetails {
    padding: rem(15px);
    border: 1px solid transparent;
    @include border-radius(rem(8px));

    &.active {
        position: relative;
        @include animation(backgroundBump ease-in-out 4s);
    }

    @media screen and (max-width:767px) {
        margin: 0 rem(-15px);
    }
}

@-webkit-keyframes backgroundBump {
    0% {
        background: #fff;
        border: 1px solid transparent;
    }

    25% {
        background: #f4fa7b;
        border: 1px solid $card-border;
    }

    100% {
        background: #fff;
        border: 1px solid transparent;
    }
}

@-moz-keyframes backgroundBump {
    0% {
        background: #fff;
        border: 1px solid transparent;
    }

    25% {
        background: #f4fa7b;
        border: 1px solid $card-border;
    }

    100% {
        background: #fff;
        border: 1px solid transparent;
    }
}

@-o-keyframes backgroundBump {
    0% {
        background: #fff;
        border: 1px solid transparent;
    }

    25% {
        background: #f4fa7b;
        border: 1px solid $card-border;
    }

    100% {
        background: #fff;
        border: 1px solid transparent;
    }
}

@keyframes backgroundBump {
    0% {
        background: #fff;
        border: 1px solid transparent;
    }

    25% {
        background: #f4fa7b;
        border: 1px solid $card-border;
    }

    100% {
        background: #fff;
        border: 1px solid transparent;
    }
}

.referenesBlock1 {
    border-bottom: rem(1px) solid $light-grey-border;
}

.referenceImg {
    width: rem(38.8px);
    height: rem(38.8px);
    margin-right: rem(5px);

    img {
        width: 30px;
        height: 30px;
    }
}

.referenceInfo {
    .secondaryElement {
        opacity: 0.9;
    }
}

.blogArticleOptions {
    @media screen and (max-width: 991px) {
        position: fixed;
        top: rem(10px);
        right: rem(30px);
    }
}

.articleInfo {
    max-height: rem(128px);
    overflow: hidden;

    @media screen and (max-width: 1199px) {
        .fixedDescription {
            max-height: rem(45px);
        }
    }
}

;

.articleCurrentView {
    font-size: rem(14px);
    @extend .secondaryElement;
}


// Notes details
.articleInlineNotes {
    position: fixed;
    width: rem(392px);
    height: calc(100% - 53px);
    top: rem(53px);
    right: rem(-422px);
    background: $white-light-bg;
    border: rem(1px) solid $light-grey-border;
    @include transition(all ease 0.35s);
    z-index: 10;

    @media screen and (max-width: 1199px) {
        width: 100%;
        height: rem(310px);
        bottom: rem(-340px);
        top: auto;
        left: auto;
        right: 0;
    }

    &.active {
        right: 0;
        @media screen and (max-width: 1199px) {
          bottom:0;
          z-index: 1000;
        }
    }

    .closeInlineNotes {
        position: absolute;
        left: rem(-28px);
        top: rem(-1px);
        background: $white-light-bg;
        border: rem(1px) solid $light-grey-border;
        padding: rem(2.5px) rem(5px);

        @media screen and (max-width: 1199px) {
            left: 0;
            top: rem(-30px);
        }
    }
}

.notesCard {
    position: relative;
    padding: rem(30px) rem(30px) rem(20px);
    @include transition(all linear 0.5s);

    .notesDropdown {
        position: absolute;
        right: rem(8px);
        top: rem(4px);
        padding: rem(5px);
    }

    .notesNumber {
        position: absolute;
        top: 0;
        left: 0;
        padding: rem(2px) rem(7px);
        background: $grey-bg;
        font-size: rem(13px);
        font-weight: 700;
        color: $white-text;
        border-radius: rem(8px) 0 rem(8px) 0;
    }

    .notesSelectionOption {
        display: none;
        margin-top: 10px;
    }

    &.newNotes {
        border-color: $brandBlue;

        .notesNumber {
            background-color: $brandBlue;
        }

        .notesDropdown {
            display: none;
        }

        .notesSelectionOption {
            display: block;
        }
    }

    .notesInformation {
        min-height: rem(80px);

        @media screen and (max-width: 1199px) {
            display: block;
            height: rem(132px);
            overflow: auto;
        }

        &:hover,
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
    &.notesListening{
        background: #f6f8b6;
    }
    @media screen and (max-width: 1199px) {
        height:214px;
    }
}

// Notes Listing
.notesList{
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    @include transition(all ease-in-out 0.5s);
    scroll-behavior: smooth;
    @media screen and (max-width: 1199px) {
        width:100%;
        max-height: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;        
        .notesCard{
            max-width: 340px;
            width: 100%;
            white-space: normal;
            display: inline-block;
            margin-right: 20px;
        }
    }
}