.card {
    padding: rem(10px);
    border-color: $card-border;
    @include border-radius(rem(8px));
    display: block;

    &.cardLg {
        padding: rem(30px);

        @media screen and (max-width: 767px) {
            padding: rem(15px);

            .h2 {
                font-size: rem(20px);
            }
        }
    }

    &.plainCard {
        border: none;
        padding: 0;
    }

    &.categoriesCard {
        padding-bottom: rem(10px);
        background: $light-blue-bg;
        border: rem(1px) solid $light-blue-border;

        .manorcategoriesBlock {
            height: rem(240px);
            overflow: hidden;

            &.active {
                height: auto;
            }
        }
    }

    &Blockquote {
        background: $light-blue-bg;
        border: rem(1px) solid $light-blue-border;
        @extend .rightTopLeftBottomRounded50;

        .blockquoteInfo {
            @include font(rem(26px), 300, null, 1.12, $lighter-black-text);

            @media screen and (max-width: 1199px) {
                font-size: rem(20px);
            }

            @media screen and (max-width: 767px) {
                font-size: rem(18px);
            }
        }
    }

    &.orangeBg {
        background: $lightRed;

        .blockquoteHeader {
            color: $white-text;
        }

        .blockquoteInfo {
            color: $white-text;
        }
    }

    &.testResultCard {
        position: relative;
        overflow: hidden;
        display: none;

        &:before {
            content: '';
            position: absolute;
            top: -60%;
            left: 0;
            width: 100%;
            height: 100%;
            background: $white-light-bg;
            @include border-radius(50%);
        }

        .testResultContent {
            position: relative;
        }

        margin-bottom: rem(30px);
    }

    &.cardGrey {
        background-color: $lightWhite;
    }

    &.cardWithScore {
        background-color: $brandBlue;
        margin-bottom: 30px;
    }
}

// Categories BG Images
.cardCategoryImage {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @include transition(all ease-in 0.5s);

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($black, 0.25);
        @include transition(all ease-in 0.5s);
    }

    &.world {
        background-image: url("https://picsum.photos/200");
    }

    &.india {
        background-image: url("https://picsum.photos/300");
    }

    &.science {
        background-image: url("https://picsum.photos/400");
    }

    &.artsCulture {
        background-image: url("https://picsum.photos/500");
    }

    &.technology {
        background-image: url("https://picsum.photos/600");
    }

    &.polity {
        background-image: url("https://picsum.photos/700");
    }

    &.sports {
        background-image: url("https://picsum.photos/800");
    }

    &.communication {
        background-image: url("https://picsum.photos/900");
    }
}

.cardCategory {
    @include font(rem(16px), 700, null, 1.25, $white-text);
    text-transform: uppercase;
    text-align: center;
    display: block;
    margin-bottom: rem(30px);
    overflow: hidden;
    padding: rem(42px) 0;
    position: relative;
    height: rem(100px);

    @media screen and (max-width: 991px) {
        font-size: rem(12px);
    }

    .cardCategoryText {
        position: relative;
        z-index: 1;
    }

    @media screen and (min-width:1200px) {

        &:hover,
        &:focus {
            color: $white-text;

            .cardCategoryImage {
                @include transform(scale(1.05));

                &:after {
                    background: rgba($black, 0.5);
                }
            }
        }
    }
}

.preLoginList {
    margin: 0;

    .preLoginArticleThumbnails {
        width: rem(60px);
        height: rem(45px);
        padding: 0;
        margin: 0;
        overflow: hidden;
        display: inline-block;

        img {
            width: 100%;
        }

        .preLoginText {
            position: absolute;
            top: 0;
            font-size: rem(24px);
            z-index: 1;
            color: $white-text;
            text-align: center;
            border-radius: rem(8px);
            padding-top: rem(5px);
            height: 100%;
            width: 100%;
            background: rgba($black, 0.5);
        }
    }
}

// Story List Items
.storyList {
    .storyItem {
        margin-bottom: rem(40px);
        position: relative;

        @media screen and (max-width: 991px) {
            margin-bottom: rem(20px);
        }

        .actualDiv {
            visibility: hidden;
            position: absolute;
            width: 100%;
            top: 0;
        }
    }

    .storyItem:last-child {
        margin-bottom: 0;
    }
}

// Card Hover Text Element
.cardHoverElement {
    width: 100%;
    margin-bottom: rem(40px);
    overflow: hidden;
    position: relative;

    &.loadingDiv {
        max-height: rem(280px);
    }

    @media screen and (max-width: 1199px) {
        margin-bottom: rem(20px);
    }

    @media screen and (max-width: 767px) {
        h5 {
            font-size: rem(18px);
        }
    }

    .gradientLinkLayer {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        z-index: 1;
        @include transition(all cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.8s);

        .overLayFloatText {
            position: absolute;
            width: 100%;
            bottom: 0;
            padding: rem(15px);
        }

        .whiteHeader {
            font-family: $secondaryFont;
            position: relative;
            z-index: 1;
        }

        &::before {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 20%, rgba(0, 0, 0, 0) 50%);
            width: 100%;
            height: 100%;
            content: '';
            z-index: 0;
        }
    }

    .actualDiv {
        position: relative;
    }

    .floatingIcon {
        position: absolute;
        top: rem(20px);
        left: rem(20px);
        z-index: 2;
        @include transition(all ease-in-out 0.5s);
    }

    .floatingIconSinglePost {
        position: absolute;
        top: calc(50% - 14px);
        left: calc(50% - 14px);
        z-index: 2;
        @include transition(all ease-in-out 0.5s);
    }

    // Placholder Image
    .ph-picture {
        height: rem(500px);
    }

    .overLayFloatText {
        .ph-item {
            background: none;
            opacity: 0.5;

            &:before {
                display: none;
            }
        }
    }

    &.cardStorypopup {

        .gradientLinkLayer {
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            z-index: 1;
            @include transition(all cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.8s);
            background: rgba($black-bg, 0.35);
    
            .overLayFloatText {
                position: absolute;
                width: 100%;
                bottom: 0;
                padding: rem(15px);
            }
    
            .whiteHeader {
                font-family: $secondaryFont;
                position: relative;
                z-index: 1;
            }
    
            &::before {
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                background-image: linear-gradient(to top, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 0) 60%);
                width: 100%;
                height: 100%;
                content: '';
                z-index: 0;
            }
        }
        .overLayFloatText {
            padding: rem(40px);
        }


        @media screen and (max-width: 1199px) {

            .h28 {
                font-size: rem(22px);
            }

            .overLayFloatText {
                padding: rem(30px);
            }
        }

        @media screen and (max-width: 991px) {

            .h28 {
                font-size: rem(20px);
            }

            .overLayFloatText {
                padding: rem(20px);
            }
        }

        @media screen and (max-width: 767px) {

            .h28 {
                font-size: rem(12px);
            }

            .overLayFloatText {
                padding: rem(15px);
            }
        }
    }
}

.cardModalDiv {
    position: relative;

    .loadingDiv {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
    }

    .actualDiv {
        position: absolute;
        visibility: hidden;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 0;
    }
}

// BlockQuote 
.blockquotedPersonality {
    position: relative;

    .quoteImg {
        width: rem(60px);
        position: relative;
        z-index: 0;
        padding-bottom: rem(22px);
    }

    .quotedBy {
        font-size: rem(14px);
        position: absolute;
        bottom: 0;
        right: 0;
        min-width: 50px;
        padding: rem(3px) rem(10px);
        z-index: 1;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background: $blue-bg;

        @media screen and (max-width: 1199px) {
            font-size: rem(12px);
            bottom: rem(-1px);
        }
    }
}

// Interesting Facts
.interestingFacts {
    position: relative;

    .interestingHeader {
        position: relative;
    }

    .carousel-indicators {
        right: auto;
        margin: 0;
        bottom: 0;

        li {
            @include border-radius(50%);
            width: rem(6px);
            height: rem(6px);
        }
    }

    #interestingFactsSlider {
        height: rem(140px);
        overflow: hidden;

        @media screen and (max-width: 1199px) {
            height: rem(200px);
        }

        @media screen and (max-width: 991px) {
            height: rem(145px);
        }

        @media screen and (max-width: 767px) {
            height: rem(120px);
        }

        @media screen and (max-width: 500px) {
            height: rem(170px);
        }
    }

    .interestingFactsDescription {
        .whiteparaGraph {
            font-size: rem(26px);
            font-weight: 200;

            @media screen and (max-width: 1199px) {
                font-size: rem(24px);
            }

            @media screen and (max-width: 767px) {
                font-size: rem(18px);
            }
        }
    }
}

// Related Topics
.relatedTopicItem {
    font-size: rem(12px);
    @extend .rounded25;
    @extend .tertiaryLink;
    background: $white-light-bg;
    border: rem(1px) solid $card-border;
    padding: rem(7px) rem(10px);
    display: block;
    text-transform: uppercase;

    @media screen and (min-width: 768px) and (max-width:991px) {
        font-size: rem(13px);
        padding: rem(12px) rem(15px);
    }
}

// Tropy Card
.testResultContent {
    position: relative;

    .tropyImage {
        width: rem(60px);
    }

    .testScore {
        font-size: rem(48px);
        line-height: rem(48px);
        color: $brandBlue;
    }
}

// Card Element in Quiz and story Cards
.cardElement {
    height: 100%;

    @media screen and (max-width: 767px) {
        p.whiteElement {
            display: none !important;
        }
    }

    @media screen and (max-width: 575px) {
        .whiteHeader {
            font-size: rem(18px);
        }

        margin-bottom: rem(40px);
    }

    @media screen and (max-width: 360px) {
        .quizLink {
            display: none;
        }
    }
}


// Sub Category Details
.cardTopDetails {
    max-height: rem(340px);
    overflow: hidden;

    @media screen and (max-width: 1199px) {
        height: 100%;
        max-height: rem(320px);
    }

    @media screen and (max-width: 992px) {
        max-height: 100%;
        height: auto;
    }

    .cardTopImg {
        height: rem(100px);

        @media screen and (max-width: 1199px) {
            height: rem(115px);
        }

        @media screen and (max-width:991px) {
            height: rem(140px);
            margin-bottom: 0;
        }
    }

    .cardTopInnerDetails {
        height: rem(130px);
        overflow: hidden;

        @media screen and (max-width: 991px) {
            height: auto;

            .fixedDescription {
                max-height: 100%;
            }
        }
    }

    // With Loader Content
    position: relative;
}

.relatedArticlesMain {
    .cardTopDetails {
        @media screen and (max-width: 767px) {
            height: auto;
        }
    }
}

.cardInnerElement {
    margin-bottom: rem(30px);
}

.storiesListMain {
    @media screen and (max-width: 767px) {
        .videosBlock {
            width: 100%;
            margin-left: 0;
        }
    }
}

// Webinar Card
.cardWebinar {
    .detailHeader {
        height: rem(60px);
        overflow: hidden;
    }

    @media screen and (max-width: 767px) {
        height: rem(380px);

        .inlineCalendar {
            margin-top: rem(15px);
        }

        .detailHeader {
            font-size: rem(22px);
        }
    }

    @media screen and (max-width: 400px) {
        .detailHeader {
            font-size: rem(19px);
        }

        .h5 {
            font-size: rem(16px);
        }

        .h7 {
            font-size: rem(12px);
        }
    }

}

// DeckCard Layout
.deckCard {
    position: relative;
    margin-right: 0;
    margin-bottom: rem(50px);
    padding: 0;
    cursor: pointer;

    &:before {
        content: '';
        width: calc(100% - 10px);
        height: 100%;
        border-color: $card-border;
        @include border-radius(rem(8px));
        border: rem(1px) solid $light-grey-border;
        position: absolute;
        top: rem(10px);
        left: rem(5px);
        z-index: 1;
        background: $white-bg;
    }

    &:after {
        content: '';
        width: calc(100% - 40px);
        height: 100%;
        border-color: $card-border;
        @include border-radius(rem(8px));
        border: rem(1px) solid $light-grey-border;
        position: absolute;
        top: rem(20px);
        left: rem(20px);
        z-index: 0;
        background: $white-bg;
    }

    .deckcardInformation {
        position: relative;
        z-index: 2;
        background: $white-bg;
        @include border-radius(rem(8px));
        min-height: rem(180px);
    }

    &.quizResultCard {
        .deckcardInformation {
            position: relative;
            height: rem(500px);

            @media screen and (max-width: 767px) {
                height: rem(325px);
            }

            overflow: hidden;

            .resultBackground {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
                width: 100%;
                height: 100%;
            }

            .confettiCanvasSection {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($blue-bg, 0.9);
            }

            .resultInformation {
                position: relative;
                z-index: 1;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.flashCardContainer {
    max-width: rem(750px);
    width: 100%;
}

// Quizzes
.quizTemplate {
    @media screen and (max-width: 991px) {
        margin-top: rem(20px);
    }
}

.quizQuestionsCountBlock {
    padding: rem(15px) rem(10px) 0 rem(22px);

    .questAnsweringLink {
        display: none;
    }

    @media screen and (max-width: 991px) {
        position: absolute;
        top: rem(280px);
        max-height: 100%;
        right: 0;
        z-index: 2;
        background: transparent;
        border: none;

        .answerInformation {
            width: rem(0px);
            @include transition(all linear 0.5s);
            opacity: 0;
            max-height: rem(300px);
            overflow: scroll;
            display: none;
        }

        .questAnsweringLink {
            background: $white-bg;
            @include box-shadow(rem(-3px) rem(3px) rem(5px) rgba($black-bg, 0.15));
            padding: rem(5px) rem(20px) rem(5px) rem(7px);
            @include border-radius(rem(4px) 0 0 rem(4px));
            position: absolute;
            top: rem(-1px);
            left: rem(-13px);
            display: block;
            border: rem(1px) solid $card-border;
            border-right: 0;
        }

        &.activeAnswered {
            background: $white-bg;
            border: rem(1px) solid $card-border;
            overflow: visible;

            .answerInformation {
                width: rem(245px);
                opacity: 1;
                display: block;
            }

            .questAnsweringLink {
                left: rem(-40px);
            }
        }
    }
}


.quizCard {
    position: relative;
    z-index: 1;

    .quizQuestion {
        max-width: rem(500px);
        height: rem(150px);
        margin: 0 auto;

        @media screen and (max-width: 991px) {
            font-size: rem(24px);
        }

        @media screen and (max-width: 767px) {
            font-size: rem(18px);
            height: rem(80px);
        }
    }

    .quizAnswerCard {
        background-color: $white-light-bg;
        border: solid rem(1px) $light-grey-border;
        text-align: left;
        font-size: rem(16px);
        color: $lighter-black-text;
        font-weight: 600;
        cursor: pointer;
    }

    .radioAnswer {
        &:checked~.quizAnswerCard {
            background-color: $light-blue-bg;
            border: solid rem(1px) $brandBlue;
            color: $brandBlue;
        }
    }

    .skipQuestion {
        position: absolute;
        right: rem(15px);
        top: rem(15px);
    }

    #startMockTest {
        width: rem(200px);
    }
}

// Mock Test
.circle-answer-list {
    li {
        position: relative;
        margin-bottom: rem(15px);
        margin-left: rem(15px);
        min-height: rem(28px);

        .answer-circle {
            display: inline-block;
            border: 1px solid $brandBlue;
            @include border-radius(50%);
            width: rem(28px);
            height: rem(28px);
            text-align: center;
            color: $brandBlue;
        }

        .answer {
            padding-left: rem(10px);
            display: inline-block;
            width: calc(100% - 40px);
            vertical-align: top;
        }

        .circle-answer-radio {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;

            &:checked~.answer-circle {
                background-color: $brandBlue;
                color: $white;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    &.freezed-answers{
        li{
            .circle-answer-radio {   
                &:checked~.answer-circle {
                    background-color: $red;     
                    border-color: $red;     
                    color: $white;            
                }
                &.correct-answer-radio{
                    &~.answer-circle {
                        background-color: $denseGreen;   
                        border-color: $denseGreen;
                        color: $white;                 
                    }
                }
            } 
        }
    }
}

.quiz-answer-list {
    li {
        font-size: rem(16px);
        padding: rem(10px) 0;
        border-top: 1px solid $lighterGrey;

        &.correct {
            span {
                color: $lightGreen;
            }
        }

        &.h7 {
            font-size: rem(14px);
        }
    }
}

.score-result {
    color: $grey;
    font-size: rem(18px);

    span {
        font-size: rem(42px);
        color: $brandBlue;
    }
}

.quiz-question-number {
    position: absolute;
    top: 0;
    left: 0;
    padding: rem(4px) rem(15px);
    color: $white-text;
    background: $brandBlue;
    @include border-radius(rem(8px) 0 rem(8px) 0);
}

.quizHeader {
    background: $white-bg;

    .testOptions {
        width: rem(55px);

        @media screen and (max-width: 767px) {
            width: rem(75px);
        }
    }
}

// No Current Affairs Card
.noCurrentAffairsCard {
    @extend .primaryElement;
    text-align: center;

    @media screen and (max-width: 991px) {
        margin: rem(55px) rem(12px) rem(10px);
    }
}



// Discussions Card
// Forums
.forumKeys {
    padding: rem(10px) rem(20px);
    background: $white-light-bg;

    a {
        padding: rem(7px) 0;
        display: block;
        @extend .secondaryElement;
        @extend .h8;
        @extend .bold;

        @media screen and (max-width: 991px) {
            &:hover {
                color: #000;
            }
        }
    }
}

.discussionInfo {
    margin-bottom: rem(15px);
    width: calc(100% - 150px);
    padding-right: rem(10px);

    @media screen and (max-width: 991px) {
        width: 100%;

        .clickableHeader,
        .discussionCarousel .clickableHeader.h5 {
            font-size: rem(16px);
        }
    }

    @media screen and (max-width: 575px) {
        .bloggedlist {
            font-size: rem(10px);

            li.question::after {
                bottom: rem(-2.5px);
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .discussionCarousel .item .clickableHeader {
        font-size: rem(18px);
    }
}

.discussionAdmin {
    width: rem(150px);
}

.dicussionTopic {
    position: relative;
    @include box-shadow(rem(-3px) rem(3px) rem(5px) rgba($black-bg, 0.085));

    .discussionTopicClose {
        position: absolute;
        top: rem(7px);
        right: rem(10px);
    }
}

.discussionInformation {
    p {
        &:last-child {
            margin: 0;
        }
    }
}

// Reply Overlay
.replySection {
    position: relative;

    .replyDropdown {
        position: absolute;
        left: auto;
        z-index: 1000;
        background: $white-bg;
        display: none;
        top: rem(30px);

        &.show {
            display: block;
        }
    }
}

.replyDropdown {
    width: rem(825px);
    right: rem(-80px);

    @media screen and (max-width: 1199px) {
        width: rem(610px);
    }

    @media screen and (max-width: 991px) {
        width: rem(520px);
        right: rem(-45px);
    }

    @media screen and (max-width: 767px) {
        width: calc(100vw - 50px);
    }

    .textEditorContainer {
        border: rem(1px) solid $brandBlue;
        @include border-radius(rem(4px) !important);

        @media screen and (max-width: 767px) {
            .textEditorControlBar {
                padding: 0 !important;
            }

            .btn {
                font-size: rem(12px);
                padding: rem(4px) rem(12px);
            }
        }
    }
}

.discussionCard:last-child {
    .replySection {
        .replyDropdown {
            top: auto;
            bottom: rem(30px);
        }
    }
}

// Card Activity
.cardActivity {
    position: relative;
    height: rem(164px);
    overflow: hidden;
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        height: rem(180px);
    }
    @media screen and (max-width: 767px) {
        height: rem(180px);
    }

    .discussionCategory {
        @extend .h8;
        @extend .leftTopRightBottomRounded8;
        @extend .whiteElement;
        position: absolute;
        top: 0;
        left: 0;
    }
}


.heightOverHidden60{
    height:70px;
    overflow: hidden;
    @media screen and (max-width: 767px) {
        height: 100%;
    }
}
