// Modules
@import "./modules/_colors";
@import "./modules/_fonts";
@import "./modules/_mixins";
@import "./modules/_variables";
@import "./modules/_common";
@import "./modules/_buttons";
@import "./modules/_icons";
@import "./modules/_tabs";
@import "./modules/_modals";
@import "./modules/_customCheckRadio";
@import "./modules/_customSelect";
@import "./modules/_tables";

// Partials
@import "./partials/_header";
@import "./partials/_footer";
@import "./partials/_headerBg";
@import "./partials/_categories";
@import "./partials/_description";
@import "./partials/_cardLayout";
@import "./partials/_timeline";
@import "./partials/_blogDetail";
@import "./partials/_answerCard";
@import "./partials/_commentBlock";
@import "./partials/_formElements";
@import "./partials/_mapLevelLayout";

// Vendor
@import "src/scss/vendor/_cardslider";
@import "src/scss/vendor/_ol";
@import "src/scss/vendor/_text-selection-actions";
@import "src/scss/vendor/_tooltipster";
@import "src/scss/vendor/_responsiveNav";
@import "src/scss/vendor/_owlCarousel";
@import "src/scss/vendor/_placeholder";
@import "src/scss/vendor/_circle";
@import "src/scss/vendor/_quill.snow";



// Common Blogging 
.bloggedlist {
    font-size: rem(12px);
    display: block;

    li {
        position: relative;

        &:after {
            position: absolute;
            content: '.';
            bottom: 0;
            right: -8px;
            color: lighten($black, 65);
            @include font-size(14px);
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        &.region {
            font-weight: 500;
            color: lighten($black, 30);
            text-transform: uppercase;
        }

        &.date {
            color: lighten($black, 65);
        }

        &.author {
            @extend .date;
        }
    }
}

// More Data
.moreData {
    @include font(11px, 600, null, 1.12, $dark-blue-text);
    text-transform: uppercase;

    @media screen and (min-width: 1200px) {
        &:hover {
            color: lighten($black, 20);
        }
    }

    &.moreWhiteData {
        color: $white-text;

        @media screen and (min-width: 1200px) {
            &:hover {
                color: $white-text
            }
        }
    }
}

.bookMarkIt {
    width: rem(20px);
}

// History Recap
.historyRecapBlock {
    padding: rem(40px);
    margin-bottom: rem(40px);
    background: lighten($black, 70);

    .historyDescription {
        height: rem(80px);
        overflow: hidden;

        &.active {
            height: auto;
        }
    }
}

.storyOnDate {
    font-size: rem(22px);

    .historyDate {
        font-size: rem(42px);
        line-height: rem(42px);
    }

    @media screen and (max-width:767px) {
        font-size: rem(16px);

        .historyDate {
            font-size: rem(32px);
        }
    }
}

// Blog Addtional Features
.blogAdditionalFeature {
    width: rem(300px);

    @media screen and (max-width: 991px) {
        width: 100%;
    }
}

// Manorama Contact Options
.contentFooter {
    .appShareOptions {
        padding: rem(7px) 0;
        border-top: rem(1px) solid $light-grey-border;
    }
}

.manoramaContacts {
    .secondaryElement {
        font-size: rem(12px);

        @media screen and (min-width: 1200px) {
            margin-left: rem(10px);

            &:hover {
                color: $black-text;
            }
        }
    }
}


// Subscribe now
.subscribeNow {
    bottom: 0;
    position: fixed;
    background-color: $white-text;
    @include box-shadow(1px -1px 5px rgba($black-bg, 0.35));
    padding: 0;
    z-index: 1000;
}

.caCards {
    position: relative;

    .preLoginSubscribe {
        @include box-shadow(0px -9px 24px rgba($black-bg, 0.35));
        position: absolute;
        bottom: 0;
        z-index: 10;
        width: 100%;
        padding: rem(15px);

        &:before {
            content: '';
            background: $white-bg;
            z-index: 1;
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.7;
        }

        .row {
            position: relative;
            z-index: 2;
        }
    }
}

// Current Affiars Navigation to current date
.currentAffairsNavigation {
    position: absolute;
    background: $white-bg;
    border: rem(1px) solid transparent;
    @include border-radius(0 0 rem(8px) rem(8px));
    margin-left: rem(58px);
    width: rem(1066px);
    top: rem(-5px);
    padding: rem(5px) rem(15px);

    @media screen and (max-width: 1199px) {
        width: rem(885px);
    }

    @media screen and (max-width: 991px) {
        margin-left: rem(-15px);
        position: relative;
        width: calc(100% - 15px) !important;
    }

    @media screen and (max-width: 767px) {
        top: rem(-5px);
        margin-left: rem(-18px);
    }

    &.stickyDateHeader {
        position: fixed;
        top: rem(90px);
        z-index: 5;
        @include transition(all linear 0.5s);
        border: rem(1px) solid $light-grey-border;
        padding-top: rem(15px) !important;

        @media screen and (max-width: 991px) {
            margin: 0 rem(-7px);
        }

        &.fixdown {
            top: rem(40px);
        }
    }
}

// Interesting  FactsList
.interestingFactsList {
    li {
        position: relative;
        font-size: rem(18px);
        @extend .secondaryElement;
        padding: rem(15px) 0 rem(15px) rem(40px);
        border-bottom: rem(1px) solid $light-grey-border;

        &:before {
            content: '';
            position: absolute;
            top: rem(12px);
            left: 0;
            background-image: url("../img/spritesheet.svg");
            width: rem(28px);
            height: rem(28px);
            background-position: rem(-206px) rem(-626px);
        }
    }
}

// Banner Link
.bannerLink {
    position: relative;

    .actualDiv {
        visibility: hidden;
        position: absolute;
        overflow: hidden;
    }

    .loadingDiv {
        margin-bottom: 0;
        position: relative;
        width: 100%;

        .bannerPicture {
            min-height: rem(200px);
        }
    }
}


// Quiz Section
.quizContainer {
    width: 100%;
    max-width: calc(100% - 375px);

    @media screen and (max-width: 1199px) {
        max-width: calc(100% - 300px);
    }

    @media screen and (max-width: 991px) {
        max-width: 100%;
    }
}

.quizControllerBlock {
    width: 100%;
    max-width: rem(275px);

    @media screen and (max-width: 991px) {
        max-width: 100%;
    }

    .quizTimerBlock {
        height: rem(48px);
        position: relative;
        overflow: hidden;

        @media screen and (max-width: 991px) {
            position: absolute;
            top: rem(110px);
            right: rem(40px);
            width: rem(270px);
            height: rem(38px);
        }

        @media screen and (max-width: 767px) {
            right: rem(26px);
            width: rem(200px);

            .timerText {
                display: none;
            }
        }

        &>div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: rem(11.5px) 0;
            white-space: nowrap;

            @media screen and (max-width: 991px) {
                padding: rem(6px) 0;
            }

            &.bottomTimer {
                z-index: 0;
                color: $lightRed;
                background: $white-bg;
            }

            &.topTimer {
                z-index: 1;
                background: $lightRed;
                border-color: $lightRed;
                color: $white-text;
                width: rem(80px);
                overflow: hidden;
            }

            &.finishedTimer {
                background: $green-bg;
                border-color: $green-bg;
                color: $white-text;
            }
        }
    }

    @media screen and (max-width: 991px) {
        .quizSubmit {
            margin: 0 auto;
        }
    }
}

.quizDetails {
    .deckCard {
        padding-right: rem(1px);
    }
}



.resultReviewBlock {
    @media screen and (max-width: 767px) {
        a {
            width: 100%;
            margin-bottom: rem(10px);
        }
    }
}

.mocktestBlock {
    .detailHeader {
        height: 60px;

        @media screen and (max-width: 767px) {
            font-size: rem(22px);
            margin-bottom: rem(15px);
        }
    }
}

// Question Number
.questionNumberBlock {
    width: rem(32px);
    height: rem(32px);
    background: $light-grey-bg;
    font-size: rem(14px);
    line-height: rem(34px);
    @extend .tertiaryElement;
    margin: rem(7px) rem(2.2px);
    @include border-radius(rem(4px));
    text-align: center;
    display: inline-block;
    border: rem(1px) solid $light-grey-bg;
    @include transition(all ease-out 0.5s);

    &.current {
        background: $brandBlue;
        color: $white-text;
        border-color: $brandBlue;
    }

    &.visited {
        background: $light-blue-bg;
        color: $brandBlue;
        border-color: $brandBlue;
    }

    &.freezeAns {
        &.skippedQues {
            background-color: $light-grey-bg;
            color: $grey-text;
            border-color: $grey-border;
        }

        &.correctAns {
            background-color: rgba(16, 204, 79, 0.1);
            color: $green-text;
            border-color: $green-border;
        }

        &.wrongAns {
            background-color: rgba(239, 75, 56, 0.1);
            color: $red-text;
            border-color: $red-border;
        }
    }
}

.postQuizBlock {
    .quizOptions {
        .quizAnswerCard {
            cursor: not-allowed;

            &.correctAnswer {
                background: $denseGreen;
                border-color: $denseGreen;
                color: $white-text;
            }
        }

        .radioAnswer {
            &:checked~.quizAnswerCard {
                background: $lightRed;
                border-color: $lightRed;
                color: $white-text;

                &.correctAnswer {
                    background: $denseGreen;
                    border-color: $denseGreen;
                }
            }
        }
    }
}

.backLink {
    position: absolute;

    @media screen and (max-width: 1760px) {
        padding-left: rem(15px);
    }

    @media screen and (max-width: 991px) {
        position: relative;
        margin-bottom: rem(10px);
        display: block;
    }
}


// Bootstrap Range Slider
.slider .tooltip {
    display: none;
}

.videoControls {
    .slider.slider-horizontal {
        width: 100%;
        height: rem(27px);

        .slider-track {
            height: rem(5px);
        }
    }

    .slider-track {
        background: $light-grey-bg;
        box-shadow: none;

        .slider-selection {
            background: $brandBlue;
            box-shadow: none;
        }
    }

    .slider {
        .min-slider-handle {
            border-radius: 0;
            background: transparent;
            position: relative;

            &::after {
                position: absolute;
                content: '';
                width: rem(18px);
                height: rem(18px);
                display: inline-block;
                vertical-align: middle;
                background-image: url("../img/spritesheet.svg");
                background-position: rem(-65px) rem(-285px);
            }
        }

        .max-slider-handle {
            border-radius: 0;
            background: transparent;

            &::after {
                position: absolute;
                content: '';
                width: rem(18px);
                height: rem(18px);
                display: inline-block;
                vertical-align: middle;
                background-image: url("../img/spritesheet.svg");
                background-position: rem(-65px) rem(-287px);
                @include transform(rotate(180deg));
            }
        }
    }
}


// White Template Section
.innerwhiteSection {
    position: relative;
    padding-top: rem(60px);
    height: 100vh;
    overflow: auto;
}

// Sort Search 
.searchTabBlock {
    position: relative;

    .sortSearch {
        position: absolute;
        right: rem(-2px);
        top: rem(4px);

        &.active {
            .iconSortSearch {
                @include transform(rotate(180deg));
            }
        }
    }
}

// Video Toogle Flash Active
.toggleVideoPlayback {
    &.active {
        .iconFlashVideo {
            background-position: rem(-271px) rem(-391px);
        }
    }
}


.wysiwyg {
    body {
        font-size: rem(14px);
        color: #495057;
    }
}

/*Community Card*/
.card-community {
    padding: rem(20px);

    .actualDiv {
        img {
            width: 72px;
            height: 72px;
            margin-right: 1.2rem;
            border-radius: 4px;
        }

        .card-info {
            height: 72px;

            h3 {
                &.card-title {
                    font-family: $secondaryFont;
                    font-weight: bold;
                    font-size: rem(20);
                    //margin-bottom: 10px;
                }
            }

            .member-count {
                font-family: $primaryFont;
                font-size: rem(16px);
                color: $lighterBlack;

                span {
                    font-size: rem(12px);
                }

                &.request {
                    color: $brandBlue;
                }
            }
        }
    }

    .card-title {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;

        @media screen and (max-width: 360px) {
            max-width: 150px;
        }
    }

    .joinCommunity {
        font-size: rem(12px);
    }
}

/*announcement-card*/
.announcement-card {
    padding: rem(20px);

    h3 {
        &.card-title {
            font-family: $secondaryFont;
            font-weight: bold;
            font-size: rem(16px);
            margin-bottom: 4px;
            color: $lighterBlack;
        }
    }

    p {
        color: $grey;
        font-family: $primaryFont;
        font-size: rem(14px);
        margin-bottom: 0;
    }

    ul {
        margin-bottom: 0;

        &.stat-list {
            li {
                display: inline-block;
                color: $grey;
                font-size: rem(12px);
                padding: 0 10px;
                border-left: 1px solid $grey-border;
                line-height: 1.33;

                a {
                    color: $lightRed;
                }

                &:first-child {
                    padding-left: 0;
                    border-left: 0 none;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    .media {
        .media-body {
            color: $grey;
            font-size: rem(12px);
            line-height: 1.33;
            text-align: right;
        }

        img {
            width: 32px;
            height: 32px;
            border-radius: 4px;
        }
    }
}

.card-about-forum {
    margin-top: 20px;
    background-color: #f6f9f9;
    padding: 20px;

    h3 {
        &.card-title {
            font-family: $primaryFont;
            font-weight: bold;
            font-size: rem(14px);
            margin-bottom: 4px;
            color: $grey;
            text-transform: uppercase;
        }
    }

    p {
        font-family: $primaryFont;
        font-weight: normal;
        font-size: rem(16px);
        color: $lighterBlack;
        line-height: normal;
    }

    ul {
        &.stat-list {
            margin: 10px 0 0;

            li {
                font-size: rem(14px);
                color: $grey;

                span {
                    color: $lighterBlack;
                    font-size: rem(20px);
                }
            }
        }
    }
}

.member-card {
    img {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        margin-right: 12px;
    }

    h3 {
        &.card-title {
            font-family: $primaryFont;
            font-weight: bold;
            font-size: rem(14px);
            line-height: 1.29;
            color: $lighterBlack;
            text-transform: uppercase;
            margin-bottom: 3px;
        }
    }

    ul {
        margin-bottom: 0;

        &.stat-list {
            li {
                float: left;
                color: $grey;
                font-size: rem(12px);
                padding: 0 10px;
                border-left: 1px solid $grey-border;
                line-height: 1;

                &:first-child {
                    padding-left: 0;
                    border-left: 0 none;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    .flashDropdown {
        position: static;
        top: initial;
        right: initial;
    }
}

.card-invite-people {
    margin-top: 40px;
    background-color: #f6f9f9;
    padding: 20px;

    h3 {
        &.card-title {
            font-family: $primaryFont;
            font-weight: bold;
            font-size: rem(14px);
            margin-bottom: 4px;
            color: $grey;
            text-transform: uppercase;
        }
    }

    .invite-input {
        a {
            font-size: rem(18px);
            position: absolute;
            text-decoration: none;
            right: 0;
            top: -1px;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .request-list {
        margin: 20px 0 0;

        li {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            img {
                width: 32px;
                height: 32px;
                border-radius: 4px;
                margin-right: 12px;
            }

            h3 {
                &.card-title {
                    font-family: $primaryFont;
                    font-weight: bold;
                    font-size: rem(14px);
                    line-height: 1.29;
                    color: $lighterBlack;
                    text-transform: uppercase;
                    margin-bottom: 0;
                }
            }

            .actions {
                a {
                    display: block;
                    font-family: $primaryFont;
                    font-weight: bold;
                    font-size: rem(12px);
                    float: left;
                    margin-right: 10px;
                }
            }
        }
    }
}

.newMembersRequest {
    .h7 {
        line-height: rem(14px);
    }
}

// About Page 
.articleCount {
    li {
        border-right: rem(1px) solid $light-grey-border;

        &:last-child {
            border: none;
        }
    }
}


// Symbols Information
.symbolInformation {
    img {
        max-width: rem(200px);
        margin: 0 auto;
    }
}

// Social Media Sharing
.socialMediaBlock {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        left: rem(9px);
        top: 0;
        width: rem(1px);
        height: 100%;
    ;
        border-left: rem(1px) solid #e3e4e5;
    }
}

// detail Page Floating Item in mobile
.floatingDetailSideList {
    @include transition(all ease-in-out 0.5s);

    .closeFloatingElement {
        display: none;
        top: 100px;
        right: 0;
        position: fixed;
        background: $white-bg;
        padding: rem(5px) rem(7px);
        @include border-radius(rem(5px) 0 0 rem(5px));
        @include box-shadow(rem(-2px) rem(-2px) rem(2px) rgba($black-bg, 0.15));
    }

    @media screen and (max-width: 575px) {
        position: fixed;
        top: 0;
        width: 100%;
        max-width: rem(280px);
        height: 100%;
        overflow: auto;
        right: -100%;
        padding: rem(120px) rem(15px) 0;
        background: $white-bg;
        border-left: rem(1px) solid $light-grey-border;
        z-index: 20;

        &.downSideDetailElement {
            padding-top: rem(70px);
        }

        .closeFloatingElement {
            right: 0;
            z-index: 10;
        }

        &.active {
            right: 0;
        }

        .closeFloatingElement {
            &.active {
                display: block;
                right: rem(278px);
            }
        }
    }
}

.floatingDetailHandler {
    display: none;
    top: 100px;
    right: 0;
    position: fixed;
    border: rem(1px) solid $light-grey-border;
    background: $white-bg;
    padding: rem(5px) rem(7px);
    @include border-radius(rem(5px) 0 0 rem(5px));
    @include box-shadow(rem(-2px) rem(-2px) rem(2px) rgba($black-bg, 0.15));

    @media screen and (max-width: 575px) {
        display: block;
    }
}

// Member Details
.memberHeaderDetails {
    .detailHeader {
        max-width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}



// Add Slot
.adSlot {
    width: 100%;
    background: $lighterBlack;
    height: 90px;
    overflow: hidden;
}

// Home Screen Carousel 
.factsCarousel {
    height: 100%;

    .interestingFactsDescription {
        margin-left: 3px;
    }

    .owl-dots {
        position: absolute;
        bottom: -7px;
        left: 0;

        .owl-dot {
            width: rem(6px);
            height: rem(6px);
            @include border-radius(50%);
            background: rgba($white-bg, 0.5);
            margin-right: 5px;

            &.active {
                background: $white-bg;
            }
        }
    }

    .owl-nav {
        display: none !important;
    }
}


/*** Modal Success Message ***/
.successsInformationBlock {
    .modalSuccessImg {
        width: rem(48px);
        height: rem(48px);
    }
}


/***
Custom Date Picker
***/
.customDatePicker {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        margin: rem(6px);
        background-image: url("../img/spritesheet.svg");
        background-position: rem(-138px) rem(-184px);
        width: rem(20px);
        height: rem(20px);
    }
}


//updates on mock test
